import styled from "styled-components";
export const ImagePickerWrapper = styled.div`
  position: relative;
  border: 0.923px dashed var(--bs-gray);
  width: 15.75631rem;
  /* height: 11.33788rem; */

  & button.image-picker-input {
    padding-block: 2.48rem 1.66rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    resize: horizontal;
    width: 100%;
    gap: 0.81rem;
    opacity: 0.5;
  }

  & img.image-picker-input {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    opacity: 0.5;
  }

  .delete {
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export const MultipleImageWrapper = styled.div`
  position: relative;
  height: 100%;
  max-height: 54vh;
  overflow-y: auto;
  gap: 1.03rem;

  .content-wrapper {
    gap: 1.03rem;
    @media (max-width: 767.98px) {
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      flex-basis: 0;
      align-items: center;
    }
  }
  /* &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    z-index: 100;
    box-shadow: inset 0px -11px 8px -10px rgba(0, 0, 0, 0.2);
  } */

  
  
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: '${(props) => props?.color}';
  justify-content: center;
  align-self: center;
  padding: 1.32rem 2.24rem;
  @media (max-width: 767.98px) {
    padding: 0;
    justify-content: center;
    max-width: fit-content;
  }
  height: 10.69663rem;
  border-radius: 0.3rem;

  .img-card {
    overflow: visible;
    height: 90%;
    aspect-ratio: 1;
    object-fit: none;
    @media (max-width: 1440px) {
      object-fit: cover;
    }
    @media (max-width: 767.98px) {
      object-fit: cover;
    }
    padding: 1rem;
  }
`;
export const CheckboxWrap = styled.img`
  cursor: pointer;
  position: absolute;
  padding: 0.54rem;
  top: 0;
  right: 0;
`;
