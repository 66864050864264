import styled from "styled-components";
import './styles.scss'

export const ListWrap = styled.div`
  display: flex;
`
export const gridOptions = {
    // set background colour on every row, this is probably bad, should be using CSS classes
    rowStyle: { background: '#FFF' },

    // set background colour on even rows again, this looks bad, should be using CSS classes
    // getRowStyle: params => {
    //     if (params.node.rowIndex % 2 === 0) {
    //         return { background: 'red' };
    //     }
    // },

    // other grid options ...
}

export const defaultColDef = {
    headerClass: 'custom-header',
    headerStyle: { backgroundColor: '#e0e0e0', padding: '1rem' }
};

export const Button = styled.button`
    color: ${(props) => props.color || "#174bd6"};
    /* font-family: Gilroy; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */

    &::before {
        content: url(${(props) => props.icon});
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.31rem;
        height: 1;
        aspect-ratio: 1;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover {
    }

    &:focus,
    &:active {
        outline: none;
        border: none;
    }
`;
