import { createAsyncThunk } from "@reduxjs/toolkit";

// create or update user list
export const updateUserListThunk = createAsyncThunk(
  'editUser',
  async ({ userId, userName, userPassword }, { rejectWithValue, dispatch }) => {
    try {
      let response;
      if (userId) {
        response = await fetch(`/api/user_list/${userId}`, {
          method: 'PUT',
          body: JSON.stringify({ name: userName, password: userPassword }),
        });
      } else {
        response = await fetch('/api/user', {
          method: 'POST',
          body: JSON.stringify({ name: userName, password: userPassword }),
        });
      }
      if (!response.ok) {
        return rejectWithValue(response.statusText);
      }
      const updatedUser = await response.json();
      return { updatedUser };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

// create or update company list
export const updateCompanyListThunk = createAsyncThunk(
  'createCompany',
  async ({ data }, { rejectWithValue, dispatch }) => {
    const { id } = data;
    try {
      let response;
      if(id) {
        response = await fetch(`/api/company_profile/${id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
        });
      } else {
        response = await fetch('/api/company', {
          method: 'POST',
          body: JSON.stringify(data),
        });
      }
      if(!response.ok) {
        return rejectWithValue(response.statusText);
      }
      return response.json();
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

