import { loginSuccess, logout } from "../slice/userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userLoginThunk = createAsyncThunk(
  'auth',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      // Simulate login with dummy data
      const user = {
        id: 1,
        name: 'John Doe',
        email: 'johndoe@example.com',
      };

      // Update the user slice with the user data
      dispatch(loginSuccess({ user }));
      return { user };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const userLogoutThunk = () => async (dispatch, getState) => {
  dispatch(logout());
};

