import styled from "styled-components";
export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
  width: 100%;

  label {
    color: var(--bs-black);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.021rem;
  }

  textarea {
    border-radius: 0;
    outline: none;
    padding: 1rem;
    box-shadow: none;
    border: 1px solid var(--bs-dark2);
    background-color: transparent;
    resize: none;
    
    &:focus,
    &:hover,
    &:active {
    border: 1px solid var(--bs-dark2);
      box-shadow: none;
    }
    
    &::placeholder {
      color: #BDBDBD;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  p.text-limit {
    color: #595E60;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`