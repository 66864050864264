import styled from "styled-components";
import { DownIcon, InfoIcon, SearchIcon } from "utils/contants";

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & label,
  .label-text {
    /* color: var("theme-text"); */
    font-family: "Gilroy-Light";
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.025rem;
    text-transform: capitalize;
    ${({ required }) =>
      required &&
      `
      &::before {
        content: "*";
      }
    `}
  }

  .input-wrapper {
    display: flex;
    border: 1px solid ${({ error }) => (error ? "var(--bs-red)" : "#11131b")};
    padding: 1rem;
    align-items: center;
    ${({ icon }) => icon && `gap: 0.625rem;`}

    .input-field {
      font-family: "Gilroy-Light";
      font-size: 1rem;
      all: unset;
      width: 100%;
      display: flex;
      border: none;
      outline: none;
      align-items: center;
      /* position: relative; */

      &:placeholder {
        font-size: 1rem;
        font-family: "Gilroy-Light";
        color: var(--bs-gray);
      }
    }

    &::before {
      content: "";
      display: flex;
      ${({ icon }) =>
        icon &&
        `
        width: 1.25rem;
        height: 1.25rem;
      `}
      height: 1.25rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(${({ icon }) => icon});
    }
  }

  .error {
    color: var(--bs-red);
    font-size: 0.75rem;
    font-family: "Gilroy-Light";
  }
`;

const buttonVariant = (variant) => {
  switch (variant) {
    default:
      return {
        bg: "--bs-red",
        color: "--bs-white",
        hover_bs: "--bs-red-darker",
        hover_color: "--bs-white",
        active_bs: "--bs-red-dark",
        active_color: "--bs-white",
      };
    case "primary black":
      return {
        bg: "--bs-dark-gray",
        color: "--bs-white",
        hover_bs: "#010308",
        hover_color: "--bs-white",
        active_bs: "#15171C",
        active_color: "--bs-white",
      };
    case "secondary blue":
      return {
        bg: "--bs-blue",
        color: "--bs-white",
        hover_bs: "--bs-blue-darker",
        hover_color: "--bs-white",
        active_bs: "--bs-blue-dark",
        active_color: "--bs-white",
      };
  }
};

export const Button = styled.button`
  display: flex;
  font-family: "Gilroy-SemiBold";
  align-items: center;
  justify-content: center;
  background-color: var(--bs-${(props) => props.color || "red"});
  color: var(--bs-white);
  padding: 1rem 2rem;
  font-size: ${(props) => (props?.large ? "1.25rem" : "1rem")};
  gap: 0.75rem;
  ${({ icon }) => {
    if (icon) {
      return `
        &::before {
            content: url(${icon});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: inline-flex;
            justify-content: center;
            // height: 1.18619rem;
            aspect-ratio: 1;
        }
      `;
    }
    return "";
  }}

  &:hover {
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
    background-color: #be0000;
    outline: 1px solid var(--bs-${(props) => props.color || "#BE0000"});
    color: var(--bs-${(props) => props.color || "#BE0000"});
  }

  &.ghost,
  &.bordered {
    background-color: transparent;
    font-size: 1rem;
    font-weight: 600;
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid var(--bs-${(props) => props.color || "red"});
    color: var(--bs-${(props) => props.color || "red"});
  }

  &.ghost {
    justify-content: flex-start;
    color: #1f1f1f;
    padding: 0.75rem 1rem;
    letter-spacing: 0.0025rem;
    white-space: nowrap;
  }
`;

export const SocialButton = styled.button`
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  color: var(--bs-black);
  border: 1px solid var(--bs-black);
  font-size: ${(props) => (props?.large ? "1.25rem" : "1rem")};
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  img {
    padding: 0.8rem 1rem;
  }

  input {
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 0.8rem 1rem;
    border-left: 1px solid var(--bs-black);
    margin: 0;
    overflow: hidden;
  }

  transition: all 90ms linear;
`;

export const PillRadio = styled.div`
  label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  input {
    /* height: 0;
        width: 0;
        visibility: hidden; */

    &:checked + span {
      background-color: var(--bs-red);
      border-color: var(--bs-red);
    }

    &:checked + span::after {
      transform: translateX(100%);
    }

    &:focus + span {
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-red-rgb), 0.25);
    }

    &:disabled + span {
      opacity: 0.65;
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 1.75rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    position: relative;

    &::after {
      content: "s";
      display: inline-block;
      background-color: #fff;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      position: absolute;
      top: 0.2rem;
      left: 0.25rem;
      transition: transform 0.15s ease-in-out;
    }
  }
`;

export const ButtonForm = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.31rem;
  color: ${(props) => `var(${props.color})` || "var(--bs-black)"};
  ${({ icon }) => {
    if (icon) {
      return `
                &::before {
                    content: url(${icon});
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    display: inline-flex;
                    justify-content: center;
                    height: 1.18619rem;
                    aspect-ratio: 1;
                }
            `;
    }
    return "";
  }}
`;

export const SmallText = styled.div`
  display: flex;
  flex-direction: row;
  ${({ icontype, iconSrc }) => {
    switch (icontype) {
      case "info":
        iconSrc = InfoIcon;
        break;
      default:
        iconSrc = null;
    }
    return `
        &::before {
            content: url(${iconSrc});
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.25rem;
            padding-right: 0.44rem;
            height: 0.75rem;
            width: 0.75rem;
            aspect-ratio: 1;
        }
    `;
  }}
  & p {
    color: var(--bs-gray);
    /* font-family: Gilroy; */
    font-size: 0.75rem;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 1.2rem;
  }
`;

export const SearchBox = styled.div`
  display: inline-flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.62rem 0.88rem;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-light-gray3);

  & input {
    flex: 1;
    border: none;
    outline: none;
    padding: 0;
    font-size: 1rem;
    background-color: var(--bs-white);
  }
  &:before {
    content: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    height: 1.25rem;
    aspect-ratio: 1;
  }
`;
export const Select = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.37rem 0.5rem;

  select {
    /* all: unset;
        margin: 0;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1rem; */
  }
  .custom-select {
    all: unset;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
  }

  &:after {
    content: url(${DownIcon});
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    height: 1.25rem;
    aspect-ratio: 1;
  }
  border: 1px solid var(--bs-light-gray2);
`;

export const TextLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: var(--bs-blue-light);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0225rem;
  text-transform: capitalize;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -0.125rem;
    left: 0;
    height: 0.125rem;
    background-color: var(--bs-blue-light);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;
