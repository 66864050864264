import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./../redux/store";



import App from "../App.jsx";
import { PageNotFound } from "../screens/NotFound/index.jsx";
import Dashboard from "../screens/Dashboard/index.jsx";
import { CompanyProfileEditor } from "../screens/CompanyProfileEditor/index.jsx";
import AdminEdit from "../screens/AdminEdit/index.jsx";
import ClientLogin from "../screens/Login/clientLogin.jsx";
import AdminLogin from "../screens/Login/adminLogin.jsx";
import NewUser from "../screens/NewUser/index.jsx";

const path = "/gptw-admin/dist/";

export const router = createBrowserRouter([
    {
        basename: path,
        element: <Provider store={store}><App /></Provider>,
        children: [
            {
                path: "*",
                element: <PageNotFound title="Page Not Found" subtitle="Oops! Something went wrong." />,
            },
            {
                path: "/",
                element: <AdminLogin />,
            },
            {
                path: "/company_profile/*",
                element: <Dashboard title="Company Profile" type="company_profile" />,
            },
            {
                path: "/user_list/*",
                element: <Dashboard title="User List" type="user_list" />,
            },
            {
                path: "/company_profile/:id/*",
                element: <CompanyProfileEditor title="Company Profile" type="company_profile" />,
            },
            {
                path: "/company_profile/quick_edit/:id/*",
                element: <AdminEdit />,
            },
            {
                path: "/user_list/add-user/*",
                element: <NewUser />,
            },
            // {
            //     path: "/client/*",
            //     element: <ClientLogin />,
            // },
            {
                path: "/admin/*",
                element: <AdminLogin />,
            },
        ],
    },
]);

