import './styles.scss';
import React, { useEffect, useState } from 'react'
import { LogoImage } from 'utils/contants'
import { HeaderRightWrapper, HeaderWrapper } from './styled'
import { useDispatch } from 'react-redux';
import { userLogoutThunk } from '../../redux/thunk/userLoginThunk';
import { Button } from '../globalComponents';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchByIdDataThunk } from '../../redux/thunk/getDataThunk';

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchByIdDataThunk({ id }));
        setData(response.payload.companyDetailsById);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, id]);
  const [fixed, setFixed] = React.useState(false);

  function handleScroll() {
    if (window.scrollY > 91) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }

  window.addEventListener('scroll', handleScroll)

  const handleLogout = async () => {
    try {
      const action = await dispatch(userLogoutThunk());
      navigate('/');
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <HeaderWrapper>
      <Button
        Link='/dashboard'
        className='ghost p-sms-0'>
        <Link to='/company_profile'><img src={LogoImage} alt="header-logo" /></Link>
        
      </Button>
      <HeaderRightWrapper>
        {data && <div className='right-wrapper'>
          <img src={data?.id_image} alt={data?.slug} />
          <p className='company-name'>{data?.company_name}</p>
        </div>}
        <Button onClick={() => handleLogout()} className='outlined'>Logout</Button>
      </HeaderRightWrapper>
    </HeaderWrapper>
  )
}

export default Navbar