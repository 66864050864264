import "./styles.scss";
import React, { useEffect, useRef, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { getDummyData, userList } from "utils/helper";
import { AddCircleIcon, EditIcon, TrashIcon } from "utils/contants";
import {
  ButtonForm,
  Button,
  SearchBox,
} from "components/globalComponents";
import { classNames } from "primereact/utils";
import { TableHeaderWrap, TableHeaderWrapper } from "./styled";
import { useNavigate } from "react-router-dom";

const template = {
  layout: "CurrentPageReport PageLinks", //"CurrentPageReport PrevPageLink PageLinks NextPageLink", //
  CurrentPageReport: (options) => {
    const { first, last, rows, totalRecords } = options;
    // options.currentPage: Current selected page.
    // options.totalPages: Total pages in paginator.
    // options.first: Zero-relative number of the first row to be displayed.
    // options.last: The number of the last row to be displayed.
    // options.rows: Row count in a page.
    // options.totalRecords: Total records in paginator.
    // options.className: Style class of the default element.
    // options.element: Default element created by the component.
    // options.props: Component props.

    return (
      <p className="pagination-buttons">
        Showing {first} to {rows} of {totalRecords} entries
      </p>
    );
  },
  PrevPageLink: (options) => {
    return (
            <button
                type='pagination-button'
                className={options.className}
                onClick={options.onClick}>
                Previous
            </button>
        );
  },
  NextPageLink: (options) => {
    return (
            <button
                type='pagination-button'
                className={options.className}
                onClick={options.onClick}>
                Next
            </button>
        );
  },
  PageLinks: (options) => {
    const className = classNames(options.className, {
      "p-disabled": true,
    });
    if (
      className.includes("p-paginator-page-start")
    ) {
      return (
        <>
          <button
            type="pagination-button"
            className={options.className.replace(" p-highlight", "")}
            onClick={options.onClick}
          >
            Previous
          </button>
          <button
            type="pagination-button"
            className={options.className}
            onClick={options.onClick}
          >
            {options.page + 1}
          </button>
        </>
      );
    }

    if (
      options.className.includes("p-paginator-page-end") &&
      options.props.totalPages - 1
    ) {

      return (
        <>
          {options.page !== options.props.totalPages - 1 ? (
            <>
              <button type="pagination-button" className={className}>...</button>
              <button
                type="pagination-button"
                className={options.className}
                onClick={options.onClick}
              >
                {options.props.totalPages}
              </button>
            </>
          ) : (
            <button
              type="pagination-button"
              className={options.className}
              onClick={options.onClick}
            >
              {options.page + 1}
            </button>
          )}
          <button
            type="pagination-button"
            className={options.className.replace(" p-highlight", "")}
            onClick={options.onClick}
          >
            Next
          </button>
        </>
      );
    }
    return (
      <button
        type="pagination-button"
        className={options.className}
        onClick={options.onClick}
      >
        {options.page + 1}
      </button>
    );
  },
};


const headerComponent = (setGlobalFilter, navigate) => {
  const handleAddNew = (e) => {
    e.preventDefault();
    navigate("/user_list/add-user/");
  }
  return (
    <TableHeaderWrap>
      <TableHeaderWrapper>
        <SearchBox>
          <input
            type="text"
            placeholder="Search"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </SearchBox>
        <Button onClick={(e) => handleAddNew(e)} type="buton" icon={AddCircleIcon}>Add New</Button>
      </TableHeaderWrapper>
    </TableHeaderWrap>
  );
};

const actionComponent = (data) => {
  return (
    <div className="actions">
      <ButtonForm color={"--bs-blue-light"} icon={EditIcon}>
        Edit
      </ButtonForm>
      <ButtonForm color={`--bs-red`} icon={TrashIcon}>
        Delete
      </ButtonForm>
    </div>
  );
};

function getBodyComponent(props, options) {
  const headerField = options["field"];
  const rowIndex = options.rowIndex;

  switch (headerField) {
    case "action":
      return actionComponent(props);
    case "id":
      return noActionComponent(props[headerField], rowIndex);
    default:
      return noActionComponent(props[headerField]);
  }
}

const noActionComponent = (data, rowIndex) => {
  if (rowIndex) {
    return <div className="no-action">{rowIndex + 1}</div>;
  } else {
    return <div className="no-action">{data}</div>;
  }
};

export function UserList() {
  const navigate = useNavigate();
  const tableRef = useRef();
  const [headerData, setHeaderData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();

  const fetchData = async (page, rows) => {
    const response = await getDummyData({ ...userList, page, rows });
    const data = response.data;
    setHeaderData(data.headerData);
    setRowData(data.rowData);
  };

  useEffect(() => {
    fetchData(0, 10);
  }, []);

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h2>User List</h2>
      </div>
      <PrimeReactProvider>
        <DataTable
          //scroll
          scrollable
          // scrollHeight={'28rem'}
          //tablevalues
          ref={tableRef}
          value={rowData}
          header={headerComponent(setGlobalFilter, navigate)}
          globalFilter={globalFilter}
          //pagination
          paginator
          emptyMessage="No Data found."
          paginatorTemplate={template}
          //styling
          className="table"
          rows={9}
          // height={400}
          //sort
          removableSort
        >
          {headerData.map((header, index) => (
            <Column
              key={index}
              style={{ width: header.field === "id" ? "7rem" : "auto" }}
              sortable={
                header.field === "name" ||
                header.field === "email" ||
                header.field === "date_added"
              }
              field={header.field}
              header={header.name}
              headerClassName="table-header"
              body={(props, options) => getBodyComponent(props, options)} // Pass the actionComponent
            />
          ))}
        </DataTable>
      </PrimeReactProvider>
    </>
  );
}
