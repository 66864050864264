import styled from "styled-components";
export const SelectWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.56rem;
`;

export const TableHeaderWrap = styled.div`
    background-color: var(--bs-white);
    border: 1px solid var(--bs-light-gray2);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
