import React from 'react'
import { TextAreaWrapper } from './styled';

// TODO : ADD AUTO ADJUST HEIGHT ON MORE LINES

function TextArea({ id, row, placeholder, name, label, value, defaultValue, onChange, textLimit = 500 }) {
  const [text, setText] = React.useState(value || '');

  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
  };

  return (
    <TextAreaWrapper>
      <textarea
        id={name} 
        maxLength={textLimit}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name} 
        className="text-area" 
        rows={row || "5"} 
        value={text} 
        onChange={(e) => handleChange(e)}
      />
      <p className="text-limit">{text.length}/{textLimit} characters left</p>
    </TextAreaWrapper>
  );
}
export default TextArea