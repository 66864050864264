export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
        return true;
    } else {
        return "Please enter a valid email address.";
    }
}


export function isValidName(name) {
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (nameRegex.test(name)) {
        return true;
    } else {
        return "Please enter a valid name.";
    }
}