import styled from "styled-components";

export const DashboardWrap = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 98%;
    width: 100%;
    overflow: hidden;
    & aside {
    }
`;

export const DashboardContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 1.62rem;
    gap: 1.13rem;
    width: 100%;
    height: 100%;
`;

export const DashboardContainer = styled.div`
    min-height: 100vh;
`;
