import React from 'react'
import { FormContainer, InputWraps } from './styled'
import { EmailIcon, LockIcon, ProfileIcon } from 'utils/contants'
import { Input } from 'components/Input'
import { Button } from 'components/globalComponents'
import { useDispatch } from 'react-redux'
import { updateCompanyListThunk } from './../../redux/thunk/updateDataThunk'

function AdminEdit() {
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    let reqmodel = Object.fromEntries(new FormData(event.target));
    try {
      dispatch(updateCompanyListThunk(reqmodel));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  return (
    <div className='d-flex justify-content-center align-items-center w-100'>
      <FormContainer onSubmit={(e) => handleSubmit(e)}>
        <InputWraps>
          <Input icon={ProfileIcon} required label="Admin Name" name="admin_name" type="text" placeholder="Enter Your Name" />
          <Input icon={EmailIcon} required label="Admin Email" name="admin_email" type="email" placeholder="Enter Your Email" />
          <Input icon={LockIcon} required label="Admin Password" name="admin_password" type="password" placeholder="Enter Your Password" />
        </InputWraps>
        <Button type="submit" style={{marginTop: '6.06rem'}}>SUBMIT</Button>

      </FormContainer>
    </div>
  )
}

export default AdminEdit