import styled from "styled-components";
export const ProfileEditorWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.97rem;
  width: 100%;
  overflow-y: auto;
`;

export const EditorContainer = styled.section`
  display: ${props => props.isNew ? "none" : "flex"};
  flex-direction: ${(props) => props.flexDirection || "column"};
  @media  (max-width: 512px) {
    flex-direction : column;
  }
  gap: ${(props) => props.gap};
  width: 100%;
  height: fit-content;
`;

export const FormContainer = styled.section`
  display: ${props => props.isNew ? "none" : "flex"};
  max-width: 90%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  flex-direction: column;
  /* gap: 1.5rem; */
  width: 100%;

  .input-container {
    display: grid;
    /* grid-template-columns: ${(props) =>
    props.grid ? "repeat(2, 1fr)" : "1fr"};
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    } */
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const SocialContainer = styled.div`
  max-width: 70rem;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(19.1875rem ,1fr));
  column-gap: 1.25rem;
  row-gap: 2.19rem;
`;

export const FormFooter = styled.div`
  padding: 1.62rem 0;
  padding-right: 2rem;
  background-color: var(--bs-white);
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  /* align-items: flex-end; */
  gap: 1.12rem;

  box-shadow: 0px -2px 35px 0px rgba(0, 0, 0, 0.08);
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.97rem;
  padding: 3.12rem 3rem;
  width: 100%;
  overflow-x: visible;
`;
