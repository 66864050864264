import "./styles.scss";
import React from "react";
import { SideBarContainer, SideBarWrap } from "./styled";
import { Button } from "../globalComponents";
import {
    BuildingIconOne,
    BuildingIconThree,
    BuildingIconTwo,
    ProfileIcon,
    SettingsIcon,
} from "utils/contants";
import { Link, NavLink } from "react-router-dom";

function index() {
    return (
        <SideBarContainer>
            <SideBarWrap>
                <Button
                    Link='/dashboard'
                    icon={BuildingIconOne}
                    className='ghost sidebar-button'>
                    <NavLink 
                        className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "inactive"}
                        to='/company_profile'>
                            Company Profile
                    </NavLink>
                </Button>
                <Button icon={BuildingIconTwo} className='ghost sidebar-button'>
                    <NavLink 
                        className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : "inactive"}
                        to='/category_list'>
                            Category List
                    </NavLink>
                </Button>
                <Button icon={ProfileIcon} className='ghost sidebar-button'>
                    <NavLink 
                        className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : "inactive"}
                        to='/user_list'>
                            User List
                    </NavLink>
                </Button>
                <Button icon={BuildingIconThree} className='ghost sidebar-button'>
                    <NavLink 
                        className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : "inactive"}
                        to='/company_internal'>
                            Company Internal
                    </NavLink>
                </Button>
            </SideBarWrap>
            <Button icon={SettingsIcon} className='ghost sidebar-button'>
                <NavLink 
                    className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : "inactive"}
                    to='/settings'>
                        Settings
                </NavLink>
            </Button>
        </SideBarContainer>
    );
}

export default index;
