import React, { useEffect } from "react";
import { Suspense, useRef } from "react";

import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand, createEditor, DecoratorNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";

const imageCache = new Set();


/**
 * Hook for loading images using React Suspense.
 * When the image is loaded, it adds it to the imageCache.
 * If the image is already in the cache, it returns immediately.
 * 
 * @param {string} src - The source of the image to load.
 * @throws {Promise} If the image is not in the cache, 
 * it throws a Promise that resolves when the image is loaded.
 */
function useSuspenseImage(src) {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve(null);
      };
    });
  }
}

function LazyImage({
  altText,
  className,
  imageRef,
  src,
  width,
  height,
  maxWidth
}) {
  useSuspenseImage(src);
  return (
    <img
      className={className || undefined}
      src={src}
      alt={altText}
      ref={imageRef}
      style={{
        height,
        maxWidth,
        width
      }}
    />
  );
}

function VideoComponent({
  src,
  altText,
  width,
  height,
  maxWidth
}) {
  const videoRef = useRef(null);

  return (
    <Suspense fallback={null}>
      <video
        ref={videoRef}
        src={src}
        alt={altText}
        style={{
          height,
          maxWidth,
          width
        }}
        controls
      ></video>
    </Suspense>
  );
}

// VIDEO NODE
function convertVideoElement(domNode) {
  if (domNode instanceof HTMLVideoElement) {
    const { src, alt: altText } = domNode;
    const node = $createVideoNode({ src, altText });
    return { node };
  }
  return null;
}


export class VideoNode extends DecoratorNode {
  __src;
  __altText;
  __width;
  __height;
  __showCaption;
  __caption;
  // Captions cannot yet be used within editor cells
  __captionsEnabled;

  static getType() {
    return "video";
  }

  /**
   * Clone - creates a new VideoNode with the same values as the provided node.
   *
   * @param {VideoNode} node - the node to clone
   * @return {VideoNode} a new VideoNode with the same values as the provided node
   */
  static clone(node) {
    return new VideoNode(
      node.__src,
      node.__altText,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__key
    );
  }

  /**
   * importJSON - Creates a new VideoNode from JSON data.
   *
   * @param {Object} data - An object containing data to create the VideoNode.
   * @param {string} data.altText - The alt text for the video.
   * @param {Object} data.caption - An object containing data to create the caption editor.
   * @param {Object} data.caption.editorState - The data of the caption editor.
   * @param {string} data.src - The source of the video.
   * @param {boolean} data.showCaption - Whether to show the caption.
   * @return {VideoNode} A new VideoNode created from the provided data.
   */
  static importJSON({altText, caption, src, showCaption}) {
    const node = $createVideoNode({altText, showCaption, src});
    const {__caption} = node;
    const editorState = __caption.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      __caption.setEditorState(editorState);
    }
    return node;
  }

  /**
   * exportDOM - Exports the VideoNode as a DOM element.
   *
   * @return {Object} An object containing a DOM video element.
   *  - {HTMLElement} element: A DOM video element.
   *    - {string} src: The source of the video.
   *    - {string} alt: The alt text of the video.
   */
  exportDOM() {
    const element = document.createElement("video");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM() {
    return {
      video: (node) => ({
        conversion: convertVideoElement,
        priority: 0
      })
    };
  }

  constructor(
    src,
    altText,
    showCaption,
    caption,
    captionsEnabled,
    key
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      src: this.getSrc(),
      type: "video",
      version: 1,
      showCaption: this.__showCaption
    };
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  // View

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.video;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <VideoComponent
          src={this.__src}
          altText={this.__altText}
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          captionsEnabled={this.__captionsEnabled}
        />
      </Suspense>
    );
  }
}

export function $createVideoNode({
  altText,
  height,
  maxWidth = 500,
  captionsEnabled,
  src,
  width,
  showCaption,
  caption,
  key
}) {
  return new VideoNode(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    key
  );
}

export function $isVideoNode(node) {
  return node instanceof VideoNode;
}


//IMAGE PLUGIN 
export const INSERT_VIDEO_COMMAND = createCommand(
  "INSERT_VIDEO_COMMAND"
);
/**
 * VideosPlugin is a LexicalComposer plugin for inserting VideoNodes into the editor.
 * It registers the `INSERT_VIDEO_COMMAND` command, which, when dispatched with a payload,
 * creates a new VideoNode and inserts it into the editor.
 * If the inserted node is a root or shadow root, it is wrapped in a paragraph node.
 *
 * @param {Object} options - The plugin options.
 * @param {boolean} options.captionsEnabled - Enables captions for the inserted image.
 * @return {null} - This function does not return anything.
 */
export function VideosPlugin({captionsEnabled}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Check if VideoNode is registered on editor
    if (!editor.hasNodes([VideoNode])) {
      throw new Error("VideosPlugin: VideoNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(INSERT_VIDEO_COMMAND,
        async (payload) => {
          const { src, ...videoProps } = payload;
          // Check if the source is valid
          if (!src || !validURL(src)) {
            console.error("Invalid video src");
            return false;
          }
          // Load video file
          const response = await fetch(src);
          if (!response.ok) {
            console.error("Failed to load video");
            return false;
          }
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          const videoNode = $createVideoNode({
            ...videoProps,
            src: objectUrl,
          });
          // Insert the videoNode into the editor
          try {
            $insertNodes([videoNode]);
          } catch (err) {
            console.error("Failed to insert video", err);
            return false;
          }
          // If the inserted videoNode is a root or shadow root, wrap it in a paragraph node and select the end
          if ($isRootOrShadowRoot(videoNode.getParentOrThrow())) {
            const newParagraph = $wrapNodeInElement(videoNode, $createParagraphNode);
            newParagraph.selectEnd();
          }
          // Revoke the object URL to free memory
          URL.revokeObjectURL(objectUrl);

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}

// Check if the provided URL is valid
function validURL(str) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

