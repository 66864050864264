import styled from "styled-components";

export const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .label {
        color: #23252C;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.0225rem;
        text-transform: capitalize;
        padding: 0;
        margin: 0;

        ${({ required }) =>
      required &&
      `
      &::before {
        content: "*";
      }
    `}
    }
`

export const ToggleContainer = styled.div`
    border-radius: 2.3125rem;
    width: fit-content;
    background-color: ${props => props.selected ? "#DCF8C0" : "#f8c0c0"};
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;
    height: 32px;
    position: relative;
    transition: all 0.3s ease;

`

export const DialogButton = styled.div`
    display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.selected ? '#5BB700' : '#b70000'};
        /* min-width: unset; */
        box-sizing: border-box;
        gap: 0.5rem;

        &:before {
            content: "";
            display: flex;
            background-color: ${props => props.selected ? '#5BB700' : '#b70000'};
            width: 0.65625rem;
            aspect-ratio: 1;
            border-radius: 50%;
            transition: all 0.3s ease;
        }
`