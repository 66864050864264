import "./styles.scss";
import React, { useState } from "react";
import {
  FacebookIcon,
  InfoIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
  GlobeIcon,
} from "utils/contants";
import {
  ContentWrap,
  EditorContainer,
  FormContainer,
  FormFooter,
  ProfileEditorWrap,
  SocialContainer,
} from "./styled";
import { ImagePickers, MultipleImagePicker } from "components/ImagePicker";
import { Button, SocialButton, TextLink } from "components/globalComponents";
import { recongniationAwardsList } from "utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "components/TextArea";
import { Input } from "components/Input";
import {TextEditor} from "components/TextEditor";
import { ToggleSwitch } from "components/ToggleSwitch";
import { companyDetails } from "utils/dummyData";

const socialMediaIcons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  youtube: YoutubeIcon,
  whatsapp: WhatsappIcon,
};

const handleSocialMedia = (data) => {
  const socialLinks = data?.social_links || {};
  const keys = ['facebook', 'twitter', 'linkedin', 'instagram', 'youtube', 'whatsapp', 'website'];
  const socialLinksObj = { ...keys.reduce((acc, key) => ({...acc, [key]: socialLinks[key] || ""}), {}), ...socialLinks };
  return Object.entries(socialLinksObj).map(([type, url], index) => {
    const Icon = socialMediaIcons[type] || GlobeIcon;
    const inputName = `social_links[${type}]`;
    return (
      <SocialButton type="button" key={index} className="outlined">
        <img onClick={() => window.open(url, "_blank")} src={Icon} alt={`${type}-alt`} />
        <input type="url" name={inputName} defaultValue={url} placeholder="Enter URL" />
      </SocialButton>
    );
  });
}

export const CompanyProfileEditor = ({ props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const sliderImagesLimit = 5;
  const [companyDetail, setCompanyDetail] = useState(
    companyDetails.data.find((item) => item.id === id) || {}
  );
   const isNewCompany= !Object.keys(companyDetail).length
  const sliderImages = companyDetail?.slider?.slice(0, sliderImagesLimit) || [];
  const socialMediaLinks = handleSocialMedia(companyDetail);


  const handleSubmit = async (event) => {
    event.preventDefault();
    let reqmodel = Object.fromEntries(new FormData(event.target));

    const newSlides = [];
    try {
      // refomatting the slides structure for backend 
      for (let i = 0; i < sliderImagesLimit; i++) {
        const fileFieldName = `slides[${i}]`;
        delete reqmodel[fileFieldName];
        const file = event.target[fileFieldName]?.files[0];
        if (file) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            newSlides.push({
              name: file.name,
              size: file.size,
              url: fileReader.result,
            });
            if (newSlides.length === sliderImagesLimit) {
              reqmodel.slides = newSlides;
              console.log(reqmodel);
            }
          };
          fileReader.readAsDataURL(file);
          console.log(fileReader);
        } else {
          // If no file is selected, use existing slide URL
          newSlides.push({
            name: sliderImages[i]?.name,
            size: sliderImages[i]?.size,
            url: sliderImages[i]?.url,
          });
          if (newSlides.length === sliderImagesLimit) {
            reqmodel.slides = newSlides;
            console.log(reqmodel);
          }
        }
      }
      // Rich Text editor structure for backend
      reqmodel.description = JSON.parse(reqmodel.description);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ProfileEditorWrap>
      <form action=""  enctype="multipart/form-data" onSubmit={(e) => handleSubmit(e)} className="w-100">
        <ContentWrap >
          <EditorContainer isNew={isNewCompany}>
            <h2 className="title">
              Add Slider Images <img src={InfoIcon} alt="info-icon" />
            </h2>
            <div className="d-flex flex-row flex-wrap gap-3">
              <ImagePickers sliderImages={sliderImages} sliderImagesLimit={5} name={"slides"} />
            </div>
          </EditorContainer>
          <EditorContainer className="row" flexDirection="row" gap="4.25rem">
            <div className="row-cols-auto w-auto">
              <h3 className="title">Company Logo</h3>
              <ImagePickers sliderImages={companyDetail.company_logo} name={"company_logo"} />
            </div>
            <div className="row-cols-auto w-auto">
              <h3 className="title">Badge Logo</h3>
              <ImagePickers sliderImages={companyDetail.company_badge} name={"company_badge"} />
            </div>
          </EditorContainer>
          <FormContainer>
            <h3 className="title">Company Details</h3>
            <div className="row gy-4 row-cols-2">
            {/* FIXME: list_category was not in design */}
              <Input
                className={"col"}
                required
                label="Company name"
                type="text"
                placeholder="Enter Company Name"
                name="company_name"
                defaultValue={companyDetail?.company_name || ""}
              />
              <Input
                className={"col"}
                required
                label="Company size"
                type="number"
                placeholder="Enter Company Size"
                name="company_size"
                defaultValue={companyDetail?.company_size || ""}
              />
              <Input
                className={"col"}
                required
                label="Industry"
                type="text"
                placeholder="Enter Industry Name"
                name="industry"
                defaultValue={companyDetail?.industry || ""}
              />
              <Input
                className={"col"}
                required
                label="City"
                type="text"
                placeholder="Enter City"
                name="city"
                defaultValue={companyDetail?.city || ""}
              />
              <Input
                className={"col"}
                required
                label="Login Email"
                type="email"
                placeholder="Enter Email"
                name="company_email"
                defaultValue={companyDetail?.company_email || ""}
              />
              <Input
                className={"col"}
                required
                label="Category Name"
                type="text"
                placeholder="Enter Category Name"
                name="category_name"
                defaultValue={companyDetail?.category_name || ""}
              />
              <Input
                className={"col"}
                required
                label="Rank"
                type="number"
                placeholder="Enter Rank"
                name="rank"
                defaultValue={companyDetail?.rank || ""}
              />
            </div>
          </FormContainer>
          <EditorContainer isNew={isNewCompany} className="row">
            <h2 className="title col-lg-12">Why {companyDetail?.company_name} is a Great Place to Work?</h2>
            <TextEditor name="why_great" className={"col-lg-12"} defaultValue={companyDetail?.description} />
          </EditorContainer>
          <EditorContainer isNew={isNewCompany} className={"row"}>
            <h2 className="title">Recognitions awarded by Great Place To Work</h2>
            <p className="col-lg-12"><TextLink target="_blank">Click here</TextLink> to read more about the evaluation process</p>
            <MultipleImagePicker imageData={recongniationAwardsList} name="recongnitions" />
          </EditorContainer>
          <EditorContainer gap={"0"}>
            <h2 className="title">
              Social Links
              <img className="header-icon" src={InfoIcon} alt="info-icon" />
            </h2>
            <SocialContainer>
              {socialMediaLinks}
              {/* FIXME: `See Jobs Here` is not there in design */}
            </SocialContainer>
          </EditorContainer>
          <EditorContainer className="row" isNew={isNewCompany}>
            <h2 className="title col-lg-12">
              Employee Quote
              <img className="header-icon" src={InfoIcon} alt="info-icon" />
            </h2>
            <div className="quotes-container gap-0 row">
              <div className="col-lg-6 col-sm-12">
                <Input
                  type="text"
                  label={"Employee Quote 1"}
                >
                  <TextArea
                    className="input-field"
                    name="empquote[quote1]"
                    placeholder={"Type the employee quote...."}
                    defaultValue={companyDetail?.empquote?.quote1 || ""}
                />
                </Input>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Input
                  label={"Employee Quote 2"}
                  >
                  <TextArea
                    type="text"
                    className="input-field"
                    name={"empquote[quote2]"}
                    placeholder={"Type the employee quote...."}
                    defaultValue={companyDetail?.empquote?.quote2 || ""}
                  />
                </Input>
              </div>
            </div>
          </EditorContainer>
          <FormContainer isNew={isNewCompany}>
            <h2 className="title">Recognitions awarded by Great Place To Work</h2>
            <p>This is necessary for onpage SEO</p>
            <div className="input-container">
              <Input
                required
                label="Page Title"
                type="text"
                placeholder="Enter Page Title"
                name="page_title"
                defaultValue={companyDetail?.page_title || ""}
              />
              <Input
                required
                label="Meta Keywords"
                type="text"
                placeholder="Enter Meta Keywords"
                name="meta_keyword"
                defaultValue={companyDetail?.meta_keyword || ""}
              />
              <Input
                required
                type="text"
                name="meta_desc"
                label={"Meta Description"}
                custom
              >
                <TextArea
                  row={4}
                  className="input-field"
                  name="meta_desc"
                  placeholder="Enter Meta Description"
                />
              </Input>
              {/*TODO: Banner is not there in design */}
            </div>
          </FormContainer>
          <FormContainer className="row"> 
            <h3 className="title col-lg-12">Company Admin Details</h3>
            <div className="input-container col-lg-12">
              <Input
                required
                label="Admin Email"
                type="text"
                placeholder="Enter Admin Email"
                name="admin_email"
                defaultValue={companyDetail?.admin_email || ""}
              />
              <Input
                required
                label="Password"
                type="text"
                placeholder="Enter Password"
                name="admin_password"
                defaultValue={companyDetail?.admin_password || ""}
              />
              <ToggleSwitch
                required
                label="Status"
                id="status"
                name={'status'}
                defaultValue={companyDetail?.status || false}
          />
            </div>
          </FormContainer>
        </ContentWrap>
        <FormFooter className="footer">
          <Button onClick={() => navigate(-1)} color={"dark-gray"} className="outlined">
            BACK TO LIST
          </Button>
          <Button color={"blue"}>VIEW PROFILE</Button>
          <Button type="submit">SUBMIT</Button>
        </FormFooter>
      </form>
    </ProfileEditorWrap>
  );
};
