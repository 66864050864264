import styled from "styled-components";
export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--bs-white);
    box-shadow: 0px 33px 54px 0px rgba(99, 99, 99, 0.05);
    padding: 2.06rem;
    min-width: 29.5rem;
    margin: 0 5rem;
  `

export const InputWraps = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
`