import { CertificateEight, CertificateFive, CertificateFour, CertificateNine, CertificateOne, CertificateSix, CertificateTen, CertificateThree, CertificateTwo, SlideFive, SlideOne, SlideThree, SlideTwo } from "./contants";

export const userList = {
    headerData: [
      {
        name: "S.No",
        field: "id",
      },
      {
        name: "Name",
        field: "name",
      },
      {
        name: "Email",
        field: "email",
      },
      {
        name: "Date Added",
        field: "date_added",
      },
      {
        name: "Action",
        field: "action",
      },
    ],
    rowData: [
      {
        id: 1,
        name: "John Doe",
        email: "jdoe@x.com",
        date_added: "2020-01-01",
        action: "",
      },
      {
        id: 2,
        name: "Jane Smith",
        email: "jsmith@x.com",
        date_added: "2020-01-02",
        action: "",
      },
      {
        id: 3,
        name: "Bob Johnson",
        email: "bjohnson@x.com",
        date_added: "2020-01-03",
        action: "",
      },
      {
        id: 4,
        name: "Alice Brown",
        email: "abrown@x.com",
        date_added: "2020-01-04",
        action: "",
      },
      {
        id: 5,
        name: "Charlie Wilson",
        email: "cwilson@x.com",
        date_added: "2020-01-05",
        action: "",
      },
      {
        id: 6,
        name: "David Davis",
        email: "ddavis@x.com",
        date_added: "2020-01-06",
        action: "",
      },
      {
        id: 7,
        name: "Emily Moore",
        email: "emoore@x.com",
        date_added: "2020-01-07",
        action: "",
      },
      {
        id: 8,
        name: "Frank Thomas",
        email: "ftomas@x.com",
        date_added: "2020-01-08",
        action: "",
      },
      {
        id: 9,
        name: "Grace Wilson",
        email: "gwilson@x.com",
        date_added: "2020-01-09",
        action: "",
      },
      {
        id: 10,
        name: "Henry Johnson",
        email: "hjohnson@x.com",
        date_added: "2020-01-10",
        action: "",
      },
      {
        id: 11,
        name: "Isabella Davis",
        email: "idavis@x.com",
        date_added: "2020-01-11",
        action: "",
      },
      {
        id: 12,
        name: "Jake Wilson",
        email: "jwilson@x.com",
        date_added: "2020-01-12",
        action: "",
      },
      {
        id: 13,
        name: "Karen Moore",
        email: "kmore@x.com",
        date_added: "2020-01-13",
        action: "",
      },
      {
        id: 14,
        name: "Lisa Thomas",
        email: "ltomas@x.com",
        date_added: "2020-01-14",
        action: "",
      },
      {
        id: 15,
        name: "Michael Davis",
        email: "mdavis@x.com",
        date_added: "2020-01-15",
        action: "",
      },
      {
        id: 16,
        name: "Olivia Wilson",
        email: "owilson@x.com",
        date_added: "2020-01-16",
        action: "",
      },
      {
        id: 17,
        name: "Patrick Moore",
        email: "pmore@x.com",
        date_added: "2020-01-17",
        action: "",
      },
      {
        id: 18,
        name: "Quinn Thomas",
        email: "qtomas@x.com",
        date_added: "2020-01-18",
        action: "",
      },
      {
        id: 19,
        name: "Rachel Davis",
        email: "rdavis@x.com",
        date_added: "2020-01-19",
        action: "",
      },
      {
        id: 20,
        name: "Stephen Wilson",
        email: "swilson@x.com",
        date_added: "2020-01-20",
        action: "",
      },
      {
        id: 21,
        name: "Tina Brown",
        email: "tbrown@x.com",
        date_added: "2020-01-21",
        action: "",
      },
      {
        id: 22,
        name: "Una Moore",
        email: "umore@x.com",
        date_added: "2020-01-22",
        action: "",
      },
      {
        id: 23,
        name: "Victor Thomas",
        email: "vthomas@x.com",
        date_added: "2020-01-23",
        action: "",
      },
      {
        id: 24,
        name: "Walter Davis",
        email: "wdavis@x.com",
        date_added: "2020-01-24",
        action: "",
      },
      {
        id: 25,
        name: "Xavier Moore",
        email: "xmoore@x.com",
        date_added: "2020-01-25",
        action: "",
      },
      {
        id: 26,
        name: "Yvonne Wilson",
        email: "ywilson@x.com",
        date_added: "2020-01-26",
        action: "",
      },
      {
        id: 27,
        name: "Zachary Thomas",
        email: "zthomas@x.com",
        date_added: "2020-01-27",
        action: "",
      },
      {
        id: 28,
        name: "Abby Brown",
        email: "abrown@x.com",
        date_added: "2020-01-28",
        action: "",
      },
      {
        id: 29,
        name: "Ben Moore",
        email: "bmoore@x.com",
        date_added: "2020-01-29",
        action: "",
      },
      {
        id: 30,
        name: "Chris Thomas",
        email: "cthomas@x.com",
        date_added: "2020-01-30",
        action: "",
      },
      {
        id: 31,
        name: "Cole Brown",
        email: "cbrown@x.com",
        date_added: "2020-01-31",
        action: "",
      },
      {
        id: 32,
        name: "Drew Wilson",
        email: "dwilson@x.com",
        date_added: "2020-02-01",
        action: "",
      },
      {
        id: 33,
        name: "Eric Thomas",
        email: "ethomas@x.com",
        date_added: "2020-02-02",
        action: "",
      },
      {
        id: 34,
        name: "Frank Moore",
        email: "fmoore@x.com",
        date_added: "2020-02-03",
        action: "",
      },
      {
        id: 35,
        name: "Grace Brown",
        email: "gbrown@x.com",
        date_added: "2020-02-04",
        action: "",
      },
      {
        id: 36,
        name: "Henry Wilson",
        email: "hWilson@x.com",
        date_added: "2020-02-05",
        action: "",
      },
      {
        id: 37,
        name: "Ivan Thomas",
        email: "ithomas@x.com",
        date_added: "2020-02-06",
        action: "",
      },
      {
        id: 38,
        name: "Jack Moore",
        email: "jmoore@x.com",
        date_added: "2020-02-07",
        action: "",
      },
      {
        id: 39,
        name: "Kate Brown",
        email: "kbrown@x.com",
        date_added: "2020-02-08",
        action: "",
      },
      {
        id: 40,
        name: "Lee Wilson",
        email: "lwilson@x.com",
        date_added: "2020-02-09",
        action: "",
      },
      {
        id: 41,
        name: "Matt Thomas",
        email: "mthomas@x.com",
        date_added: "2020-02-10",
        action: "",
      },
      {
        id: 42,
        name: "Nancy Moore",
        email: "nmoore@x.com",
        date_added: "2020-02-11",
        action: "",
      },
      {
        id: 43,
        name: "Owen Brown",
        email: "obrown@x.com",
        date_added: "2020-02-12",
        action: "",
      },
      {
        id: 44,
        name: "Paul Wilson",
        email: "pwilson@x.com",
        date_added: "2020-02-13",
        action: "",
      },
      {
        id: 45,
        name: "Quincy Thomas",
        email: "qthomas@x.com",
        date_added: "2020-02-14",
        action: "",
      },
      {
        id: 46,
        name: "Ralph Moore",
        email: "rmoore@x.com",
        date_added: "2020-02-15",
        action: "",
      },
      {
        id: 47,
        name: "Sidney Brown",
        email: "sbrown@x.com",
        date_added: "2020-02-16",
        action: "",
      },
      {
        id: 48,
        name: "Tim Wilson",
        email: "twilson@x.com",
        date_added: "2020-02-17",
        action: "",
      },
      {
        id: 49,
        name: "Una Thomas",
        email: "uthomas@x.com",
        date_added: "2020-02-18",
        action: "",
      },
      {
        id: 50,
        name: "Vince Moore",
        email: "vmoore@x.com",
        date_added: "2020-02-19",
        action: "",
      },
      {
        id: 51,
        name: "Wade Brown",
        email: "wbrown@x.com",
        date_added: "2020-02-20",
        action: "",
      },
      {
        id: 52,
        name: "Xavier Johnson",
        email: "xjohnson@x.com",
        date_added: "2020-02-21",
        action: "",
      },
      {
        id: 53,
        name: "Yvonne Thomas",
        email: "ythomas@x.com",
        date_added: "2020-02-22",
        action: "",
      },
      {
        id: 54,
        name: "Zachary Moore",
        email: "zmoore@x.com",
        date_added: "2020-02-23",
        action: "",
      },
      {
        id: 55,
        name: "Ava Johnson",
        email: "ajohnson@x.com",
        date_added: "2020-02-24",
        action: "",
      },
      {
        id: 56,
        name: "Blake Brown",
        email: "bbrown@x.com",
        date_added: "2020-02-25",
        action: "",
      },
      {
        id: 57,
        name: "Cameron Wilson",
        email: "cwilson@x.com",
        date_added: "2020-02-26",
        action: "",
      },
      {
        id: 58,
        name: "Dora Thomas",
        email: "dthomas@x.com",
        date_added: "2020-02-27",
        action: "",
      },
      {
        id: 59,
        name: "Evan Moore",
        email: "emoore@x.com",
        date_added: "2020-02-28",
        action: "",
      },
      {
        id: 60,
        name: "Frances Brown",
        email: "febrown@x.com",
        date_added: "2020-02-29",
        action: "",
      },
      {
        id: 61,
        name: "Gerald Wilson",
        email: "gwilson@x.com",
        date_added: "2020-03-01",
        action: "",
      },
      {
        id: 62,
        name: "Hazel Thomas",
        email: "htomas@x.com",
        date_added: "2020-03-02",
        action: "",
      },
      {
        id: 63,
        name: "Ignatius Moore",
        email: "imoore@x.com",
        date_added: "2020-03-03",
        action: "",
      },
      {
        id: 64,
        name: "Jasper Brown",
        email: "jbrown@x.com",
        date_added: "2020-03-04",
        action: "",
      },
      {
        id: 65,
        name: "Keith Wilson",
        email: "kwilson@x.com",
        date_added: "2020-03-05",
        action: "",
      },
      {
        id: 66,
        name: "Lydia Thomas",
        email: "lthomas@x.com",
        date_added: "2020-03-06",
        action: "",
      },
      {
        id: 67,
        name: "Morgan Moore",
        email: "mmoore@x.com",
        date_added: "2020-03-07",
        action: "",
      },
      {
        id: 68,
        name: "Nathan Brown",
        email: "nbrown@x.com",
        date_added: "2020-03-08",
        action: "",
      },
      {
        id: 69,
        name: "Oliver Wilson",
        email: "owilson@x.com",
        date_added: "2020-03-09",
        action: "",
      },
    ],
  };
  
export const companyList = {
headerData: [
    {
    name: "",
    field: "id_image",
    },
    {
    name: "Company Name",
    field: "name",
    },
    {
    name: "Industry Name",
    field: "industry_name",
    },
    {
    name: "Username & Password",
    field: "username_password",
    },
    {
    name: "Size",
    field: "size",
    },
    {
    name: "URL",
    field: "url",
    },
],
rowData: [
    {
    id_image: "https://logo.clearbit.com/google.com",
    id: "company-1",
    name: { value: "Google", city: "US" },
    industry_name: "Tech",
    username_password: "jdoe@gmail.com",
    size: "023",
    url: "https://google.com",
    },
    {
    id_image: "https://logo.clearbit.com/microsoft.com",
    id: "company-2",
    name: { value: "Microsoft", city: "US" },
    industry_name: "Tech",
    username_password: "jdoe@microsoft.com",
    size: "005",
    url: "https://microsoft.com",
    },
    {
    id_image: "https://logo.clearbit.com/facebook.com",
    id: "company-3",
    name: { value: "Facebook", city: "US" },
    industry_name: "Social",
    username_password: "mmiller@facebook.com",
    size: "009",
    url: "https://facebook.com",
    },
    {
    id_image: "https://logo.clearbit.com/disney.com",
    id: "company-4",
    name: { value: "Disney", city: "US" },
    industry_name: "Entertainment",
    username_password: "rjones@disney.com",
    size: "005",
    url: "https://disney.com",
    },
    {
    id_image: "https://logo.clearbit.com/airbnb.com",
    id: "company-5",
    name: { value: "Airbnb", city: "US" },
    industry_name: "Travel",
    username_password: "kdavis@airbnb.com",
    size: "015",
    url: "https://airbnb.com",
    },
    {
    id_image: "https://logo.clearbit.com/shopify.com",
    id: "company-6",
    name: { value: "Shopify", city: "US" },
    industry_name: "Tech",
    username_password: "sroberts@shopify.com",
    size: "023",
    url: "https://shopify.com",
    },
    {
    id_image: "https://logo.clearbit.com/netflix.com",
    id: "company-7",
    name: { value: "Netflix", city: "US" },
    industry_name: "Entertainment",
    username_password: "jturner@netflix.com",
    size: "005",
    url: "https://netflix.com",
    },
    {
    id_image: "https://logo.clearbit.com/ebay.com",
    id: "company-8",
    name: { value: "Ebay", city: "US" },
    industry_name: "Shopping",
    username_password: "emartin@ebay.com",
    size: "015",
    url: "https://ebay.com",
    },
    {
    id_image: "https://logo.clearbit.com/intel.com",
    id: "company-9",
    name: { value: "Intel", city: "US" },
    industry_name: "Tech",
    username_password: "lthomas@intel.com",
    size: "005",
    url: "https://intel.com",
    },
    {
    id_image: "https://logo.clearbit.com/apple.com",
    id: "company-10",
    name: { value: "Apple", city: "US" },
    industry_name: "Tech",
    username_password: "jlee@apple.com",
    size: "023",
    url: "https://apple.com",
    },
    {
    id_image: "https://logo.clearbit.com/tesla.com",
    id: "company-11",
    name: { value: "Tesla", city: "US" },
    industry_name: "Auto",
    username_password: "cwright@tesla.com",
    size: "015",
    url: "https://tesla.com",
    },
    {
    id_image: "https://logo.clearbit.com/amazon.com",
    id: "company-12",
    name: { value: "Amazon", city: "US" },
    industry_name: "E-commerce",
    username_password: "jsmith@amazon.com",
    size: "023",
    url: "https://amazon.com",
    },
],
};

export const companyDetails = {
data: [
    {
      id_image: "https://logo.clearbit.com/google.com",
      id: "company-1",
      // company details
      company_name: "Google",
      slug: "google",
      company_size: "023",
      industry: "Tech",
      city: "US",
      company_email: "jdoe@gmail.com",
      category_name: "Technology",
      rank: 12,
      url: "https://google.com",
      // add slider images
      slider: [
          {
            src: "https://media.istockphoto.com/id/487361187/photo/buying-a-lottery-ticket-las-ramblas.jpg?s=2048x2048&w=is&k=20&c=QSOvZHp11X9V0_GquqaD5YronNUHnA12LROySr7sGtw=",
            id: "slide-1",
            name: "buying-a-lottery-ticket-las-ramblas.jpg",
          },
          {
            src: "https://media.istockphoto.com/id/1295274245/photo/random-multicolored-spheres-computer-generated-abstract-form-of-large-and-small-balls-3d.jpg?s=2048x2048&w=is&k=20&c=1Co69LBO9-M7pPj2HFrOODc1q1CnvqCbLJL2MawWaUQ=",
            id: "slide-2",
            name: "random-multicolored-spheres-computer-generated-abstract-form-of-large-and-small-balls-3d.jpg",
          },
      ],
      // company logo
      company_logo: {
        name: "Google",
        src: 'https://4kwallpapers.com/images/walls/thumbs_3t/11298.png',
        id:'https://4kwallpapers.com/images/walls/thumbs_3t/11298.png',
      },
      company_badge: {
        name: "Google-badge",
        src:'https://lh3.googleusercontent.com/0bBfFEkB6ypK6aYw3JAjrk9Kkut-mICiVuQBFBLPdJRcXmUs6RJv3TSEb0HrJteG9Eyx9ITFMEOW10FrqDC41hQVsaukAgdtsXdNjis=w1024-l90-sg-rj-c0xffffff',
        id:'https://lh3.googleusercontent.com/0bBfFEkB6ypK6aYw3JAjrk9Kkut-mICiVuQBFBLPdJRcXmUs6RJv3TSEb0HrJteG9Eyx9ITFMEOW10FrqDC41hQVsaukAgdtsXdNjis=w1024-l90-sg-rj-c0xffffff',
      },
      // social links
      social_links: {
        facebook: "https://www.facebook.com/google",
        twitter: "https://twitter.com/google",
        linkedin: "https://www.linkedin.com/company/google",
        instagram: "https://www.instagram.com/google/",
        youtube: "https://www.youtube.com/google",
        whatsapp: "https://www.whatsapp.com/google",
        website: "https://www.google.com",
      },
      empquote: {
        quote1: "Employee 1",
        quote2: "Employee 2",
      },
      page_title: "Google",
      page_description: "Google",
      admin_email: "jdoe@gmail",
      admin_password: "123456",
      status: true,
    },
    {
      id_image: "https://logo.clearbit.com/facebook.com",
      id: "company-3",
      company_name: "Facebook",
      slug: "facebook",
      city: "US",
      industry: "Social",
      company_email: "jsmith@gmail.com",
      category_name: "Social",
      rank: 12,
      company_size: "023",
      url: "https://facebook.com",
      slider: [
          {
          src: SlideFive,
          id: "slide-1",
          title: "Slide 1",
          },
          {
          src: SlideThree,
          id: "slide-2",
          title: "Slide 2",
          },
          {
          src: SlideThree,
          id: "slide-3",
          title: "Slide 3",
          },
      ],
      social_links: {
        facebook: "https://www.facebook.com/facebook",
        twitter: "https://twitter.com/facebook",
        linkedin: "https://www.linkedin.com/company/facebook",
        instagram: "https://www.instagram.com/facebook/",
        youtube: "https://www.youtube.com/c/Facebook",
        whatsapp: "https://wa.me/",
        website: "https://www.facebook.com",
      },
      employee_quote_1: "Employee 1",
      employee_quote_2: "Employee 2",
      page_title: "Facebook",
      page_description: "Facebook",
    },
],
};

export const recongniationAwardsList = [
{
    src: CertificateOne,
    title:
    "Best Multinational Workplaces in Asia 2022. Certified for 5 years in a row!",
    id: "best-mulitnational-workplaces-in-asia-2022-certified-for-5-years-in-a-row",
    color: "#CCF5FF",
},
{
    src: CertificateTwo,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-1",
    color: "#CFFFFF",
},
{
    src: CertificateThree,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-2",
    color: "#C7ABF5",
},
{
    src: CertificateFour,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-3",
    color: "#C7ABF5",
},
{
    src: CertificateFive,
    title: "Commitment to Being a Great Place to Work. (Valid till July 2022)",
    id: "commitment-to-being-a-great-place-to-work-valid-till-july-2022",
    color: "#C7ABF5",
},
{
    src: CertificateSix,
    title:
    "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-1",
},
{
    src: CertificateEight,
    title:
    "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-2",
},
{
    src: CertificateNine,
    title:
    "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-3",
},
{
    src: CertificateTen,
    title:
    "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-3",
},
];