import './styles.scss';
import React, { useEffect, useState } from 'react'
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils'

import { Button } from './styled';
import { EditIcon, TrashIcon } from 'utils/contants';
import { getDummyData, userList } from 'utils/helper';


const defaultTemplate = {
  layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
  'CurrentPageReport': (options) => {
    const {first, last, rows, totalRecords} = options;
    // options.currentPage: Current selected page.
    // options.totalPages: Total pages in paginator.
    // options.first: Zero-relative number of the first row to be displayed.
    // options.last: The number of the last row to be displayed.
    // options.rows: Row count in a page.
    // options.totalRecords: Total records in paginator.
    // options.className: Style class of the default element.
    // options.element: Default element created by the component.
    // options.props: Component props.

    return <p className='pagination-buttons'>Showing {first} to {rows} of {totalRecords} entries</p>;
  },
  'PrevPageLink': (options) => {
      // options.onClick: Click event for the default element.
      // options.className: Style class of the default element.
      // options.iconClassName: Style class of the default icon element.
      // options.disabled: Indicates whether the element is disabled.
      // options.element: Default element created by the component.
      // options.props: Component props.

      return <button type="pagination-button" className={options.className} onClick={options.onClick}>Previous</button>;
  },
  'PageLinks': (options) => {
    if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.props.totalPages)) {
        const className = classNames(options.className, { 'p-disabled': true });
        return <>
          <span className={className}>...</span>
          <button type="pagination-button" className={options.className} onClick={options.onClick}>
            {/* {options.props.totalPages + 1} */}
        </button>
        </>;
    }


    return (
        <button type="pagination-button" className={options.className} onClick={options.onClick}>
            {options.page + 1}
        </button>
    )
  },
  'NextPageLink': (options) => {
    const { totalRecords } = options.props;
      // options.onClick: Click event for the default element.
      // options.className: Style class of the default element.
      // options.iconClassName: Style class of the default icon element.
      // options.disabled: Indicates whether the element is disabled.
      // options.element: Default element created by the component.
      // options.props: Component props.
      return <button type="pagination-button" className={options.className} onClick={options.onClick}>Next</button>;

  }

};

export const bottomTempalte = {
  layout: "CurrentPageReport PageLinks", //"CurrentPageReport PrevPageLink PageLinks NextPageLink", //
  CurrentPageReport: (options) => {
    const { first, last, rows, totalRecords } = options;
    // options.currentPage: Current selected page.
    // options.totalPages: Total pages in paginator.
    // options.first: Zero-relative number of the first row to be displayed.
    // options.last: The number of the last row to be displayed.
    // options.rows: Row count in a page.
    // options.totalRecords: Total records in paginator.
    // options.className: Style class of the default element.
    // options.element: Default element created by the component.
    // options.props: Component props.

    return (
      <p className="pagination-buttons">
        Showing {first} to {rows} of {totalRecords} entries
      </p>
    );
  },
  PrevPageLink: (options) => {
    return (
            <button
                type='pagination-button'
                className={options.className}
                onClick={options.onClick}>
                Previous
            </button>
        );
  },
  NextPageLink: (options) => {
    return (
            <button
                type='pagination-button'
                className={options.className}
                onClick={options.onClick}>
                Next
            </button>
        );
  },
  PageLinks: (options) => {
    const className = classNames(options.className, {
      "p-disabled": true,
    });
    if (
      className.includes("p-paginator-page-start")
    ) {
      return (
        <>
          <button
            type="pagination-button"
            className={options.className.replace(" p-highlight", "")}
            onClick={options.onClick}
          >
            Previous
          </button>
          <button
            type="pagination-button"
            className={options.className}
            onClick={options.onClick}
          >
            {options.page + 1}
          </button>
        </>
      );
    }

    if (
      options.className.includes("p-paginator-page-end") &&
      options.props.totalPages - 1
    ) {

      return (
        <>
          {options.page !== options.props.totalPages - 1 ? (
            <>
              <button type="pagination-button" className={className}>...</button>
              <button
                type="pagination-button"
                className={options.className}
                onClick={options.onClick}
              >
                {options.props.totalPages}
              </button>
            </>
          ) : (
            <button
              type="pagination-button"
              className={options.className}
              onClick={options.onClick}
            >
              {options.page + 1}
            </button>
          )}
          <button
            type="pagination-button"
            className={options.className.replace(" p-highlight", "")}
            onClick={options.onClick}
          >
            Next
          </button>
        </>
      );
    }
    return (
      <button
        type="pagination-button"
        className={options.className}
        onClick={options.onClick}
      >
        {options.page + 1}
      </button>
    );
  },
};

const actionComponent = (data) => {
  return (
    <div className='actions'>
      <Button icon={EditIcon}>Edit</Button>
      <Button color={"#E60000"} icon={TrashIcon}>
        Delete
      </Button>
    </div>
  );
};

const noActionComponent = (data) => {
  return <div className='no-action'>{data}</div>;
};

function ListComponent() {
  const tableRef = React.useRef();
  const [headerData, setHeaderData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const fetchData = async (page, rows) => {
    const response = await getDummyData({ ...userList, page, rows });
    const data = response.data;
    setHeaderData(data.headerData);
    setRowData(data.rowData);
  };

  useEffect(() => {
    fetchData(0, 10);
  }, []);


  return (
    <PrimeReactProvider>
      <DataTable
        ref={tableRef}
        value={rowData}
        header={headerData.field}
        paginator
        rows={9}
        height={400}
        emptyMessage="No Data found."
        paginatorTemplate={defaultTemplate}
        // rowsPerPageOptions={[5, 10, 25, 50]}
        className='table'>
        {headerData.map((header, index) => (
          <Column
            key={index}
            field={header.field}
            header={header.name}
            headerClassName='table-header'
            body={(props) =>
              header.field === "action"
                ? actionComponent(props)
                : noActionComponent(props[header.field])
            } // Pass the actionComponent
          />
        ))}
      </DataTable>
      {/* <Paginator first={first} rows={10} totalRecords={50} onPageChange={onPageChange} 
    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} /> */}
    </PrimeReactProvider>
  );
}

export default ListComponent

