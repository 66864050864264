import styled from "styled-components";
export const TableHeaderWrap = styled.div`
    /* background-color: var(--bs-white); */
    /* border: 1px solid var(--bs-light-gray2); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    `;

export const TableHeaderWrapper = styled.div`
    display: flex;
    padding: 1.25rem 1.5rem !important;
    justify-content: space-between;
    width: 100%;
`