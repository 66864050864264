import React from "react";
import { Input as StyledInput } from "components/globalComponents";

export function Input({
  icon,
  required,
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  defaultValue,
  custom,
  children,
  className,
  error,
}) {
  return (
    <StyledInput className={className} error={error} icon={icon} required={required}>
      <label className="label-text" htmlFor={name}>
        {label}
      </label>
      {children ? children : <span className="input-wrapper">
          <input
            className="input-field"
            type={type}
            placeholder={placeholder}
            name={name}
            defaultValue={defaultValue}
            />
          </span>
        }
        <span className="error">{error}</span>
    </StyledInput>
  );
}
