import './styles.scss';
import {
  Container,
  FormContainer,
  InputWraps,
  LeftWrap,
  LoginWrap,
  RightWrap,
} from "./styled";

// import LoginBanner from './../../assets/images/login-banner.png';
import { useDispatch } from "react-redux";
import { userLoginThunk } from "../../redux/thunk/userLoginThunk";
import { useNavigate } from "react-router-dom";
import {
  EmailIcon,
  LockIcon,
  LoginBanner,
  LogoImage,
} from "utils/contants";
import { Button, SmallText } from "components/globalComponents";
import { Input } from "components/Input";

function ClientLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Import the navigate hook

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqmodel = Object.fromEntries(new FormData(event.target));

    try {
      const action = await dispatch(userLoginThunk(reqmodel)); // Dispatch the login thunk
      navigate('/company_profile/'); // Navigate to /company_profile on success

    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  return (
    <Container>
      <LoginWrap className="row">
        <LeftWrap className="col-lg-6">
          <img className='login-banner-client' src={LoginBanner} alt="LoginBanner" />
        </LeftWrap>
        <LeftWrap className="col-lg-6 d-none">
        </LeftWrap>
        <RightWrap className="col-lg-6">
          <div />
          <div className="form-wrapper">
            <img className='login-logo' src={LogoImage} alt="LogoImage" />
            <h2 className="text-center">Welcome to Great Place to work</h2>
            <FormContainer onSubmit={handleSubmit}>
              <InputWraps>
                <Input
                  icon={EmailIcon}
                  required
                  label={"Email"}
                  type={"email"}
                  placeholder={"Enter Email"}
                  name={"email"}
                />
                <Input
                  icon={LockIcon}
                  required
                  label={"Password"}
                  type={"password"}
                  placeholder={"Enter Password"}
                  name={"password"}
                />
              </InputWraps>

              <Button type="submit" style={{ marginTop: "6.06rem" }}>
                SIGN IN
              </Button>
              <SmallText icontype={"info"} className={"mt-3"}>
                <p>
                  If you are Great Place to Work India&apos;s Customer, kindly
                  contact your RM for your login credentials to edit your
                  Company&apos;s profile page
                </p>
              </SmallText>
            </FormContainer>
          </div>
          <p className="mt-4 text-center">
              {/* FOOTER */}
            Copyright © 2023 Great Place To Work® India.
            <br />
            All rights reserved.
          </p>
        </RightWrap>
      </LoginWrap>
    </Container>
  );
}

export default ClientLogin;
