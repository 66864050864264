import { createAsyncThunk } from "@reduxjs/toolkit";
import { companyDetails, companyList, recongniationAwardsList } from "utils/dummyData";
import { loginSuccess } from "../slice/userSlice";

export const fetchAllDataThunk = createAsyncThunk(
    'fetchAllData',
    async ({ getState }, { rejectWithValue, dispatch }) => {
      const { isLoggedIn } = getState().user;
      if(!isLoggedIn){
        return rejectWithValue("User is not logged in");
      }

      try {
        // Simulate data fetch with dummy data (replace with your actual API call)
        const allData = {
          companyList,
          companyDetails,
          recongniationAwardsList,
        };
  
        // Update the user slice with the fetched data
        dispatch(loginSuccess({ allData }));
        return { allData };
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
  }
);

export const fetchCompanyListThunk = createAsyncThunk(
    'fetchCompanyList',
    async ({ user }, { rejectWithValue, dispatch }) => {
      try {
        // Simulate data fetch with dummy data (replace with your actual API call)
        const response = companyList.data;
        if (!response) {
          return rejectWithValue(`Company list not found`);
        }
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
)

export const fetchByIdDataThunk = createAsyncThunk(
    'fetchByIdData',
    async ({ id }, { rejectWithValue, dispatch }) => {
      try {
        // Simulate data fetch with dummy data (replace with your actual API call)
        const companyDetailsById = companyDetails.data.find((company) => company.id === id);
        if (!companyDetailsById) {
          return rejectWithValue(`Company with id ${id} not found`);
        }
        return {companyDetailsById};
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
);