import {
  CertificateEight,
  CertificateFive,
  CertificateFour,
  CertificateNine,
  CertificateOne,
  CertificateSix,
  CertificateTen,
  CertificateThree,
  CertificateTwo,
  SlideFive,
  SlideOne,
  SlideThree,
  SlideTwo,
} from "./contants";

export const userList = {
  headerData: [
    {
      name: "S.No",
      field: "id",
    },
    {
      name: "Name",
      field: "name",
    },
    {
      name: "Email",
      field: "email",
    },
    {
      name: "Date Added",
      field: "date_added",
    },
    {
      name: "Action",
      field: "action",
    },
  ],
  rowData: [
    {
      id: 1,
      name: "John Doe",
      email: "jdoe@x.com",
      date_added: "2020-01-01",
      action: "",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jsmith@x.com",
      date_added: "2020-01-02",
      action: "",
    },
    {
      id: 3,
      name: "Bob Johnson",
      email: "bjohnson@x.com",
      date_added: "2020-01-03",
      action: "",
    },
    {
      id: 4,
      name: "Alice Brown",
      email: "abrown@x.com",
      date_added: "2020-01-04",
      action: "",
    },
    {
      id: 5,
      name: "Charlie Wilson",
      email: "cwilson@x.com",
      date_added: "2020-01-05",
      action: "",
    },
    {
      id: 6,
      name: "David Davis",
      email: "ddavis@x.com",
      date_added: "2020-01-06",
      action: "",
    },
    {
      id: 7,
      name: "Emily Moore",
      email: "emoore@x.com",
      date_added: "2020-01-07",
      action: "",
    },
    {
      id: 8,
      name: "Frank Thomas",
      email: "ftomas@x.com",
      date_added: "2020-01-08",
      action: "",
    },
    {
      id: 9,
      name: "Grace Wilson",
      email: "gwilson@x.com",
      date_added: "2020-01-09",
      action: "",
    },
    {
      id: 10,
      name: "Henry Johnson",
      email: "hjohnson@x.com",
      date_added: "2020-01-10",
      action: "",
    },
    {
      id: 11,
      name: "Isabella Davis",
      email: "idavis@x.com",
      date_added: "2020-01-11",
      action: "",
    },
    {
      id: 12,
      name: "Jake Wilson",
      email: "jwilson@x.com",
      date_added: "2020-01-12",
      action: "",
    },
    {
      id: 13,
      name: "Karen Moore",
      email: "kmore@x.com",
      date_added: "2020-01-13",
      action: "",
    },
    {
      id: 14,
      name: "Lisa Thomas",
      email: "ltomas@x.com",
      date_added: "2020-01-14",
      action: "",
    },
    {
      id: 15,
      name: "Michael Davis",
      email: "mdavis@x.com",
      date_added: "2020-01-15",
      action: "",
    },
    {
      id: 16,
      name: "Olivia Wilson",
      email: "owilson@x.com",
      date_added: "2020-01-16",
      action: "",
    },
    {
      id: 17,
      name: "Patrick Moore",
      email: "pmore@x.com",
      date_added: "2020-01-17",
      action: "",
    },
    {
      id: 18,
      name: "Quinn Thomas",
      email: "qtomas@x.com",
      date_added: "2020-01-18",
      action: "",
    },
    {
      id: 19,
      name: "Rachel Davis",
      email: "rdavis@x.com",
      date_added: "2020-01-19",
      action: "",
    },
    {
      id: 20,
      name: "Stephen Wilson",
      email: "swilson@x.com",
      date_added: "2020-01-20",
      action: "",
    },
    {
      id: 21,
      name: "Tina Brown",
      email: "tbrown@x.com",
      date_added: "2020-01-21",
      action: "",
    },
    {
      id: 22,
      name: "Una Moore",
      email: "umore@x.com",
      date_added: "2020-01-22",
      action: "",
    },
    {
      id: 23,
      name: "Victor Thomas",
      email: "vthomas@x.com",
      date_added: "2020-01-23",
      action: "",
    },
    {
      id: 24,
      name: "Walter Davis",
      email: "wdavis@x.com",
      date_added: "2020-01-24",
      action: "",
    },
    {
      id: 25,
      name: "Xavier Moore",
      email: "xmoore@x.com",
      date_added: "2020-01-25",
      action: "",
    },
    {
      id: 26,
      name: "Yvonne Wilson",
      email: "ywilson@x.com",
      date_added: "2020-01-26",
      action: "",
    },
    {
      id: 27,
      name: "Zachary Thomas",
      email: "zthomas@x.com",
      date_added: "2020-01-27",
      action: "",
    },
    {
      id: 28,
      name: "Abby Brown",
      email: "abrown@x.com",
      date_added: "2020-01-28",
      action: "",
    },
    {
      id: 29,
      name: "Ben Moore",
      email: "bmoore@x.com",
      date_added: "2020-01-29",
      action: "",
    },
    {
      id: 30,
      name: "Chris Thomas",
      email: "cthomas@x.com",
      date_added: "2020-01-30",
      action: "",
    },
    {
      id: 31,
      name: "Cole Brown",
      email: "cbrown@x.com",
      date_added: "2020-01-31",
      action: "",
    },
    {
      id: 32,
      name: "Drew Wilson",
      email: "dwilson@x.com",
      date_added: "2020-02-01",
      action: "",
    },
    {
      id: 33,
      name: "Eric Thomas",
      email: "ethomas@x.com",
      date_added: "2020-02-02",
      action: "",
    },
    {
      id: 34,
      name: "Frank Moore",
      email: "fmoore@x.com",
      date_added: "2020-02-03",
      action: "",
    },
    {
      id: 35,
      name: "Grace Brown",
      email: "gbrown@x.com",
      date_added: "2020-02-04",
      action: "",
    },
    {
      id: 36,
      name: "Henry Wilson",
      email: "hWilson@x.com",
      date_added: "2020-02-05",
      action: "",
    },
    {
      id: 37,
      name: "Ivan Thomas",
      email: "ithomas@x.com",
      date_added: "2020-02-06",
      action: "",
    },
    {
      id: 38,
      name: "Jack Moore",
      email: "jmoore@x.com",
      date_added: "2020-02-07",
      action: "",
    },
    {
      id: 39,
      name: "Kate Brown",
      email: "kbrown@x.com",
      date_added: "2020-02-08",
      action: "",
    },
    {
      id: 40,
      name: "Lee Wilson",
      email: "lwilson@x.com",
      date_added: "2020-02-09",
      action: "",
    },
    {
      id: 41,
      name: "Matt Thomas",
      email: "mthomas@x.com",
      date_added: "2020-02-10",
      action: "",
    },
    {
      id: 42,
      name: "Nancy Moore",
      email: "nmoore@x.com",
      date_added: "2020-02-11",
      action: "",
    },
    {
      id: 43,
      name: "Owen Brown",
      email: "obrown@x.com",
      date_added: "2020-02-12",
      action: "",
    },
    {
      id: 44,
      name: "Paul Wilson",
      email: "pwilson@x.com",
      date_added: "2020-02-13",
      action: "",
    },
    {
      id: 45,
      name: "Quincy Thomas",
      email: "qthomas@x.com",
      date_added: "2020-02-14",
      action: "",
    },
    {
      id: 46,
      name: "Ralph Moore",
      email: "rmoore@x.com",
      date_added: "2020-02-15",
      action: "",
    },
    {
      id: 47,
      name: "Sidney Brown",
      email: "sbrown@x.com",
      date_added: "2020-02-16",
      action: "",
    },
    {
      id: 48,
      name: "Tim Wilson",
      email: "twilson@x.com",
      date_added: "2020-02-17",
      action: "",
    },
    {
      id: 49,
      name: "Una Thomas",
      email: "uthomas@x.com",
      date_added: "2020-02-18",
      action: "",
    },
    {
      id: 50,
      name: "Vince Moore",
      email: "vmoore@x.com",
      date_added: "2020-02-19",
      action: "",
    },
    {
      id: 51,
      name: "Wade Brown",
      email: "wbrown@x.com",
      date_added: "2020-02-20",
      action: "",
    },
    {
      id: 52,
      name: "Xavier Johnson",
      email: "xjohnson@x.com",
      date_added: "2020-02-21",
      action: "",
    },
    {
      id: 53,
      name: "Yvonne Thomas",
      email: "ythomas@x.com",
      date_added: "2020-02-22",
      action: "",
    },
    {
      id: 54,
      name: "Zachary Moore",
      email: "zmoore@x.com",
      date_added: "2020-02-23",
      action: "",
    },
    {
      id: 55,
      name: "Ava Johnson",
      email: "ajohnson@x.com",
      date_added: "2020-02-24",
      action: "",
    },
    {
      id: 56,
      name: "Blake Brown",
      email: "bbrown@x.com",
      date_added: "2020-02-25",
      action: "",
    },
    {
      id: 57,
      name: "Cameron Wilson",
      email: "cwilson@x.com",
      date_added: "2020-02-26",
      action: "",
    },
    {
      id: 58,
      name: "Dora Thomas",
      email: "dthomas@x.com",
      date_added: "2020-02-27",
      action: "",
    },
    {
      id: 59,
      name: "Evan Moore",
      email: "emoore@x.com",
      date_added: "2020-02-28",
      action: "",
    },
    {
      id: 60,
      name: "Frances Brown",
      email: "febrown@x.com",
      date_added: "2020-02-29",
      action: "",
    },
    {
      id: 61,
      name: "Gerald Wilson",
      email: "gwilson@x.com",
      date_added: "2020-03-01",
      action: "",
    },
    {
      id: 62,
      name: "Hazel Thomas",
      email: "htomas@x.com",
      date_added: "2020-03-02",
      action: "",
    },
    {
      id: 63,
      name: "Ignatius Moore",
      email: "imoore@x.com",
      date_added: "2020-03-03",
      action: "",
    },
    {
      id: 64,
      name: "Jasper Brown",
      email: "jbrown@x.com",
      date_added: "2020-03-04",
      action: "",
    },
    {
      id: 65,
      name: "Keith Wilson",
      email: "kwilson@x.com",
      date_added: "2020-03-05",
      action: "",
    },
    {
      id: 66,
      name: "Lydia Thomas",
      email: "lthomas@x.com",
      date_added: "2020-03-06",
      action: "",
    },
    {
      id: 67,
      name: "Morgan Moore",
      email: "mmoore@x.com",
      date_added: "2020-03-07",
      action: "",
    },
    {
      id: 68,
      name: "Nathan Brown",
      email: "nbrown@x.com",
      date_added: "2020-03-08",
      action: "",
    },
    {
      id: 69,
      name: "Oliver Wilson",
      email: "owilson@x.com",
      date_added: "2020-03-09",
      action: "",
    },
    {
      id: 70,
      name: "Penelope Moore",
      email: "pmoore@x.com",
      date_added: "2020-03-10",
      action: "",
    },
    {
      id: 71,
      name: "Quentin Brown",
      email: "qbrown@x.com",
      date_added: "2020-03-11",
      action: "",
    },
    {
      id: 72,
      name: "Rebecca Wilson",
      email: "rwilson@x.com",
      date_added: "2020-03-12",
      action: "",
    },
    {
      id: 73,
      name: "Sophia Thomas",
      email: "stomas@x.com",
      date_added: "2020-03-13",
      action: "",
    },
    {
      id: 74,
      name: "Taylor Moore",
      email: "tmoore@x.com",
      date_added: "2020-03-14",
      action: "",
    },
    {
      id: 75,
      name: "Una Brown",
      email: "ubrown@x.com",
      date_added: "2020-03-15",
      action: "",
    },
    {
      id: 76,
      name: "Vincent Wilson",
      email: "vwilson@x.com",
      date_added: "2020-03-16",
      action: "",
    },
    {
      id: 77,
      name: "William Thomas",
      email: "wthomas@x.com",
      date_added: "2020-03-17",
      action: "",
    },
    {
      id: 78,
      name: "Xavier Moore",
      email: "xmoore@x.com",
      date_added: "2020-03-18",
      action: "",
    },
    {
      id: 79,
      name: "Yolanda Brown",
      email: "ybrown@x.com",
      date_added: "2020-03-19",
      action: "",
    },
    {
      id: 80,
      name: "Zachery Wilson",
      email: "zwilson@x.com",
      date_added: "2020-03-20",
      action: "",
    },
    {
      id: 81,
      name: "Ava Moore",
      email: "amoe@x.com",
      date_added: "2020-03-21",
      action: "",
    },
    {
      id: 82,
      name: "Blake Brown",
      email: "bbrown@x.com",
      date_added: "2020-03-22",
      action: "",
    },
    {
      id: 83,
      name: "Cameron Wilson",
      email: "cwilson@x.com",
      date_added: "2020-03-23",
      action: "",
    },
    {
      id: 84,
      name: "Dora Thomas",
      email: "dthomas@x.com",
      date_added: "2020-03-24",
      action: "",
    },
    {
      id: 85,
      name: "Evan Moore",
      email: "emoore@x.com",
      date_added: "2020-03-25",
      action: "",
    },
    {
      id: 86,
      name: "Frances Brown",
      email: "fbrown@x.com",
      date_added: "2020-03-26",
      action: "",
    },
    {
      id: 87,
      name: "Gerald Wilson",
      email: "gwilson@x.com",
      date_added: "2020-03-27",
      action: "",
    },
    {
      id: 88,
      name: "Hazel Thomas",
      email: "htomas@x.com",
      date_added: "2020-03-28",
      action: "",
    },
    {
      id: 89,
      name: "Isabella Brown",
      email: "ibrown@x.com",
      date_added: "2020-03-29",
      action: "",
    },
    {
      id: 90,
      name: "Julian Wilson",
      email: "jwilson@x.com",
      date_added: "2020-03-30",
      action: "",
    },
    {
      id: 91,
      name: "Karen Thomas",
      email: "kthomas@x.com",
      date_added: "2020-03-31",
      action: "",
    },
    {
      id: 92,
      name: "Kevin Moore",
      email: "kmoore@x.com",
      date_added: "2020-04-01",
      action: "",
    },
    {
      id: 93,
      name: "Lance Wilson",
      email: "lwilson@x.com",
      date_added: "2020-04-02",
      action: "",
    },
    {
      id: 94,
      name: "Mackey Moore",
      email: "mmoore@x.com",
      date_added: "2020-04-03",
      action: "",
    },
    {
      id: 95,
      name: "Nicole Brown",
      email: "nibrown@x.com",
      date_added: "2020-04-04",
      action: "",
    },
    {
      id: 96,
      name: "Owen Thomas",
      email: "othomas@x.com",
      date_added: "2020-04-05",
      action: "",
    },
    {
      id: 97,
      name: "Patrick Davis",
      email: "pdavis@x.com",
      date_added: "2020-04-06",
      action: "",
    },
    {
      id: 98,
      name: "Quincy Wilson",
      email: "qwilson@x.com",
      date_added: "2020-04-07",
      action: "",
    },
    {
      id: 99,
      name: "Rachel Thomas",
      email: "rthomas@x.com",
      date_added: "2020-04-08",
      action: "",
    },
    {
      id: 100,
      name: "Sage Moore",
      email: "smoore@x.com",
      date_added: "2020-04-09",
      action: "",
    },
    {
      id: 101,
      name: "Terence Brown",
      email: "tbrown@x.com",
      date_added: "2020-04-10",
      action: "",
    },
    {
      id: 102,
      name: "Upton Wilson",
      email: "uwilson@x.com",
      date_added: "2020-04-11",
      action: "",
    },
    {
      id: 103,
      name: "Virgil Thomas",
      email: "vthomas@x.com",
      date_added: "2020-04-12",
      action: "",
    },
    {
      id: 104,
      name: "Walter Moore",
      email: "wmoore@x.com",
      date_added: "2020-04-13",
      action: "",
    },
    {
      id: 105,
      name: "Xavier Wilson",
      email: "xwilson@x.com",
      date_added: "2020-04-14",
      action: "",
    },
    {
      id: 106,
      name: "Yvette Thomas",
      email: "ythomas@x.com",
      date_added: "2020-04-15",
      action: "",
    },
    {
      id: 107,
      name: "Zeke Brown",
      email: "zbrown@x.com",
      date_added: "2020-04-16",
      action: "",
    },
    {
      id: 108,
      name: "Alison Moore",
      email: "amore@x.com",
      date_added: "2020-04-17",
      action: "",
    },
    {
      id: 109,
      name: "Brandon Wilson",
      email: "bwilson@x.com",
      date_added: "2020-04-18",
      action: "",
    },
    {
      id: 110,
      name: "Cindy Thomas",
      email: "ctomas@x.com",
      date_added: "2020-04-19",
      action: "",
    },
    {
      id: 111,
      name: "David Davis",
      email: "ddavis@x.com",
      date_added: "2020-04-20",
      action: "",
    },
    {
      id: 112,
      name: "Ethan Brown",
      email: "ebrown@x.com",
      date_added: "2020-04-21",
      action: "",
    },
  ],
};

export const companyList = {
  headerData: [
    {
      name: "",
      field: "id_image",
    },
    {
      name: "Company Name",
      field: "name",
    },
    {
      name: "Industry Name",
      field: "industry_name",
    },
    {
      name: "Username & Password",
      field: "username_password",
    },
    {
      name: "Size",
      field: "size",
    },
    {
      name: "URL",
      field: "url",
    },
  ],
  rowData: [
    {
      id_image: "https://logo.clearbit.com/google.com",
      id: "company-1",
      name: { value: "Google", location: "US" },
      industry_name: "Tech",
      username_password: "jdoe@gmail.com",
      size: "023",
      url: "https://google.com",
    },
    {
      id_image: "https://logo.clearbit.com/microsoft.com",
      id: "company-2",
      name: { value: "Microsoft", location: "US" },
      industry_name: "Tech",
      username_password: "jdoe@microsoft.com",
      size: "005",
      url: "https://microsoft.com",
    },
    {
      id_image: "https://logo.clearbit.com/facebook.com",
      id: "company-3",
      name: { value: "Facebook", location: "US" },
      industry_name: "Social",
      username_password: "mmiller@facebook.com",
      size: "009",
      url: "https://facebook.com",
    },
    {
      id_image: "https://logo.clearbit.com/disney.com",
      id: "company-4",
      name: { value: "Disney", location: "US" },
      industry_name: "Entertainment",
      username_password: "rjones@disney.com",
      size: "005",
      url: "https://disney.com",
    },
    {
      id_image: "https://logo.clearbit.com/airbnb.com",
      id: "company-5",
      name: { value: "Airbnb", location: "US" },
      industry_name: "Travel",
      username_password: "kdavis@airbnb.com",
      size: "015",
      url: "https://airbnb.com",
    },
    {
      id_image: "https://logo.clearbit.com/shopify.com",
      id: "company-6",
      name: { value: "Shopify", location: "US" },
      industry_name: "Tech",
      username_password: "sroberts@shopify.com",
      size: "023",
      url: "https://shopify.com",
    },
    {
      id_image: "https://logo.clearbit.com/netflix.com",
      id: "company-7",
      name: { value: "Netflix", location: "US" },
      industry_name: "Entertainment",
      username_password: "jturner@netflix.com",
      size: "005",
      url: "https://netflix.com",
    },
    {
      id_image: "https://logo.clearbit.com/ebay.com",
      id: "company-8",
      name: { value: "Ebay", location: "US" },
      industry_name: "Shopping",
      username_password: "emartin@ebay.com",
      size: "015",
      url: "https://ebay.com",
    },
    {
      id_image: "https://logo.clearbit.com/intel.com",
      id: "company-9",
      name: { value: "Intel", location: "US" },
      industry_name: "Tech",
      username_password: "lthomas@intel.com",
      size: "005",
      url: "https://intel.com",
    },
    {
      id_image: "https://logo.clearbit.com/apple.com",
      id: "company-10",
      name: { value: "Apple", location: "US" },
      industry_name: "Tech",
      username_password: "jlee@apple.com",
      size: "023",
      url: "https://apple.com",
    },
    {
      id_image: "https://logo.clearbit.com/tesla.com",
      id: "company-11",
      name: { value: "Tesla", location: "US" },
      industry_name: "Auto",
      username_password: "cwright@tesla.com",
      size: "015",
      url: "https://tesla.com",
    },
    {
      id_image: "https://logo.clearbit.com/amazon.com",
      id: "company-12",
      name: { value: "Amazon", location: "US" },
      industry_name: "E-commerce",
      username_password: "jsmith@amazon.com",
      size: "023",
      url: "https://amazon.com",
    },
  ],
};

export const companyDetails = {
  data: [
    {
      id_image: "https://logo.clearbit.com/google.com",
      id: "company-1",
      company_name: "Google",
      slug: "google",
      location: "US",
      industry: "Tech",
      company_email: "jdoe@gmail.com",
      category_name: "Technology",
      rank: 12,
      company_size: "023",
      url: "https://google.com",
      slides: [
        {
          src: SlideOne,
          id: "slide-1",
          title: "Slide 1",
        },
        {
          src: SlideTwo,
          id: "slide-2",
          title: "Slide 2",
        },
      ],
    },
    {
      id_image: "https://logo.clearbit.com/facebook.com",
      id: "company-3",
      company_name: "Facebook",
      slug: "facebook",
      location: "US",
      industry: "Social",
      company_email: "jsmith@gmail.com",
      category_name: "Social",
      rank: 12,
      company_size: "023",
      url: "https://facebook.com",
      slides: [
        {
          src: SlideFive,
          id: "slide-1",
          title: "Slide 1",
        },
        {
          src: SlideThree,
          id: "slide-2",
          title: "Slide 2",
        },
        {
          src: SlideThree,
          id: "slide-3",
          title: "Slide 3",
        },
      ],
    },
  ],
};

export const recongniationAwardsList = [
  {
    src: CertificateOne,
    title:
      "Best Multinational Workplaces in Asia 2022. Certified for 5 years in a row!",
    id: "best-mulitnational-workplaces-in-asia-2022-certified-for-5-years-in-a-row",
    color: getDominantColor(CertificateOne),
  },
  {
    src: CertificateTwo,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-1",
    color: "#CFFFFF",
  },
  {
    src: CertificateThree,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-2",
    color: "#C7ABF5",
  },
  {
    src: CertificateFour,
    title: "India's Best Workplaces™ for Women 2023",
    id: "indias-best-workplaces-women-2023-3",
    color: "#C7ABF5",
  },
  {
    src: CertificateFive,
    title: "Commitment to Being a Great Place to Work. (Valid till July 2022)",
    id: "commitment-to-being-a-great-place-to-work-valid-till-july-2022",
    color: "#C7ABF5",
  },
  {
    src: CertificateSix,
    title:
      "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-1",
  },
  {
    src: CertificateEight,
    title:
      "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-2",
  },
  {
    src: CertificateNine,
    title:
      "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-3",
  },
  {
    src: CertificateTen,
    title:
      "India's Best Workplaces among Organizations with more than 10,000 Employees, 2021",
    id: "indias-best-workplaces-among-organizations-with-more-than-10000-employees-2021-3",
  },
];

export const getDummyData = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data,
        status: 200,
      });
    }, 1000);
  });
};

export const globalFilterListOptions = [
  { value: "Education", label: "Education" },
  { value: "Tech", label: "Tech" },
  { value: "Retail", label: "Retail" },
  { value: "Finance", label: "Finance" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Consulting", label: "Consulting" },
  { value: "Food & Beverage", label: "Food & Beverage" },
]

export const dynamicPageTitle = (title) => {
  document.title = title;
};

export async function getDominantColor(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const { naturalWidth, naturalHeight } = this;
      canvas.width = naturalWidth;
      canvas.height = naturalHeight;
      ctx.drawImage(this, 0, 0);

      const imageData = ctx.getImageData(0, 0, naturalWidth, naturalHeight)
        .data;
      let r = 0;
      let g = 0;
      let b = 0;
      let count = 0;
      for (let i = 0; i < imageData.length; i += 4) {
        r += imageData[i];
        g += imageData[i + 1];
        b += imageData[i + 2];
        count++;
      }
      const avgR = Math.floor(r / count);
      const avgG = Math.floor(g / count);
      const avgB = Math.floor(b / count);
      const hex = `#${((avgR << 16) | (avgG << 8) | avgB).toString(16).padStart(
        6,
        "0"
      )}`;
      resolve(hex);
    };

    img.onerror = function (err) {
      reject(err);
    };
  });
}


/**
 * Generates a strong random id using crypto module
 * @returns {string} The generated id
 */
export function generateId() {
  return crypto.randomUUID().replace(/-/g, "");
}

