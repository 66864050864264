import "./styles.scss";
import React from "react";
import { DashboardWrap } from "./styled";
import { UserList } from "./../UserList";
import { CompanyProfile } from "../CompanyProfile";

function Dashboard({ title, type }) {
    // dynamicPageTitle
    return (
        <>
            <DashboardWrap>
                <div className='table-container'>
                    {type === "user_list" && <UserList />}
                    {type === "company_profile" && <CompanyProfile />}
                </div>
            </DashboardWrap>
        </>
    );
}

export default Dashboard;
