import React, { useEffect } from "react";
import { Suspense, useRef } from "react";

import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand, createEditor, DecoratorNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";

const imageCache = new Set();


/**
 * Hook for loading images using React Suspense.
 * When the image is loaded, it adds it to the imageCache.
 * If the image is already in the cache, it returns immediately.
 * 
 * @param {string} src - The source of the image to load.
 * @throws {Promise} If the image is not in the cache, 
 * it throws a Promise that resolves when the image is loaded.
 */
function useSuspenseImage(src) {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve(null);
      };
    });
  }
}

function LazyImage({
  altText,
  className,
  imageRef,
  src,
  width,
  height,
  maxWidth
}) {
  useSuspenseImage(src);
  return (
    <img
      className={className || undefined}
      src={src}
      alt={altText}
      ref={imageRef}
      style={{
        height,
        maxWidth,
        width
      }}
    />
  );
}

function ImageComponent({
  src,
  altText,
  width,
  height,
  maxWidth
}) {
  const imageRef = useRef(null);

  return (
    <Suspense fallback={null}>
      <>
        <div>
          <LazyImage
            className=""
            src={src}
            altText={altText}
            imageRef={imageRef}
            width={width}
            height={height}
            maxWidth={maxWidth}
          />
        </div>
      </>
    </Suspense>
  );
}

// IMAGE NODE
/**
 * convertImageElement - function that takes a DOM node and if it's an image
 * element, it creates an ImageNode with the same alt text and src and
 * returns an object with the newly created node. If the node is not an
 * image element, it returns null.
 *
 * @param {Node} domNode - the DOM node to be converted
 * @return {Object|null} - an object with the newly created ImageNode or null
 */
function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src } = domNode;
    const node = $createImageNode({ altText, src });
    return { node };
  }
  return null;
}


export class ImageNode extends DecoratorNode {
  __src;
  __altText;
  __width;
  __height;
  __maxWidth;
  __showCaption;
  __caption;
  // Captions cannot yet be used within editor cells
  __captionsEnabled;

  static getType() {
    return "image";
  }

  /**
   * Clone - creates a new ImageNode with the same values as the provided node.
   *
   * @param {ImageNode} node - the node to clone
   * @return {ImageNode} a new ImageNode with the same values as the provided node
   */
  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__key
    );
  }

  /**
   * importJSON - Creates a new ImageNode from JSON data.
   *
   * @param {Object} data - An object containing data to create the ImageNode.
   * @param {string} data.altText - The alt text for the image.
   * @param {number} data.height - The height of the image.
   * @param {number} data.maxWidth - The maximum width of the image.
   * @param {Object} data.caption - An object containing data to create the caption editor.
   * @param {Object} data.caption.editorState - The data of the caption editor.
   * @param {string} data.src - The source of the image.
   * @param {boolean} data.showCaption - Whether to show the caption.
   * @param {number} data.width - The width of the image.
   * @return {ImageNode} A new ImageNode created from the provided data.
   */
  static importJSON({altText, height, maxWidth, caption, src, showCaption, width}) {
    const node = $createImageNode({altText, height, maxWidth, showCaption, src, width});
    const {__caption} = node;
    const editorState = __caption.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      __caption.setEditorState(editorState);
    }
    return node;
  }

  /**
   * exportDOM - Exports the ImageNode as a DOM element.
   *
   * @return {Object} An object containing a DOM img element.
   *  - {HTMLElement} element: A DOM img element.
   *    - {string} src: The source of the image.
   *    - {string} alt: The alt text of the image.
   */
  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0
      })
    };
  }

  constructor(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    key
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      src: this.getSrc(),
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width
    };
  }

  setWidthAndHeight(
    width,
    height
  ) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  // View

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          captionsEnabled={this.__captionsEnabled}
          resizable={true}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  altText,
  height,
  maxWidth = 500,
  captionsEnabled,
  src,
  width,
  showCaption,
  caption,
  key
}) {
  return new ImageNode(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    key
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}


//IMAGE PLUGIN 
export const INSERT_IMAGE_COMMAND = createCommand(
  "INSERT_IMAGE_COMMAND"
);
/**
 * ImagesPlugin is a LexicalComposer plugin for inserting ImageNodes into the editor.
 * It registers the `INSERT_IMAGE_COMMAND` command, which, when dispatched with a payload,
 * creates a new ImageNode and inserts it into the editor.
 * If the inserted node is a root or shadow root, it is wrapped in a paragraph node.
 *
 * @param {Object} options - The plugin options.
 * @param {boolean} options.captionsEnabled - Enables captions for the inserted image.
 * @return {null} - This function does not return anything.
 */
export function ImagesPlugin({
  captionsEnabled
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Check if ImageNode is registered on editor
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(INSERT_IMAGE_COMMAND,
        (payload) => {
          const { src, ...imageProps } = payload;
          // Check if the source is valid
          if (!src || !validURL(src)) {
            console.error("Invalid image src");
            return false;
          }
          // Check if the image type is supported
          if (!validURL(src) && !isValidImageType(src)) {
            console.error("Unsupported image type");
            return false;
          }
          // Create a new ImageNode with the provided payload
          const imageNode = $createImageNode({
            ...imageProps,
            src: src.startsWith("data:") ? src : `data:image/png;base64,${src}`,
          });
          // Insert the ImageNode into the editor
          try {
            $insertNodes([imageNode]);
          } catch (err) {
            console.error("Failed to insert image", err);
            return false;
          }
          // If the inserted ImageNode is a root or shadow root, wrap it in a paragraph node and select the end
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            const newParagraph = $wrapNodeInElement(imageNode, $createParagraphNode);
            newParagraph.selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}

// Check if the provided URL is valid
function validURL(str) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

// Check if the image type is supported
function isValidImageType(src) {
  const image = new Image();
  image.src = src;
  return !!image.width;
}
  


