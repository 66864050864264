import "./App.scss";

import { FontVariable, GlobalVariables } from "./styles/globalStyled";
import {useSelector} from "react-redux";
import Navbar from "components/NavbarComponent";
import { Routes, Route } from "react-router-dom";
import Dashboard from "screens/Dashboard";
import Sidebar from "components/SideBar";
import { CompanyProfileEditor } from "screens/CompanyProfileEditor";
import AdminLogin from "screens/Login/adminLogin";
import AdminEdit from "screens/AdminEdit";
import NewUser from "screens/NewUser";
import { PageNotFound } from "screens/NotFound";


function App() {
  const {isLoggedIn} = useSelector((state) => state.user);
  console.log(isLoggedIn)

  return (
    <>
      <GlobalVariables />
      <FontVariable />

      {!isLoggedIn ? (
        <Routes>
          {/* <Route path="/admin/*" element={<AdminLogin />} /> */}
          <Route path="/*" element={<AdminLogin />} />
        </Routes>
      ) : (
        <>
          <Navbar className={"fixed"} path="/company_profile/:id" />
          <div className="main_container">
            <Sidebar />
            <Routes>
              <Route path="*" component={PageNotFound} />
              <Route
                path="/company_profile/*"
                element={
                  <Dashboard title="Company Profile" type="company_profile" />
                }
              />
              <Route
                path="/user_list/*"
                element={<Dashboard title="User List" type="user_list" />}
              />
              <Route
                path="/company_profile/:id/*"
                element={
                  <CompanyProfileEditor
                    title="Company Profile"
                    type="company_profile"
                  />
                }
              />
              <Route
                path="/company_profile/quick_edit/:id/*"
                element={
                  <AdminEdit />
                }
              />
              <Route path="/user_list/add-user/*" element={<NewUser />} />
            </Routes>
          </div>
        </>
      )}
    </>
  );
}

export default App;
