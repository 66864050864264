import styled from "styled-components";
import { AdminLoginBanner } from "utils/contants";

export const LoginWrap = styled.section`
    display: flex;
    height: 100vh;
    /* padding: 12.56rem 5.06rem; */
  `

export const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 90rem;
    overflow: hidden;
`

export const LeftWrap = styled.div`
  @media (max-width: 993px) {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${props => props.isAdmin && `
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 36.77%, rgba(0, 0, 0, 0.00) 57.91%), url(${AdminLoginBanner});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    gap: 1.25rem;
    padding-inline: 4.94rem 7.37rem;
    padding-bottom: 7.19rem;
  `}
  .login-banner-client {
    max-width: 100%;
    height: 90%;
    object-fit: contain;
  }
`;
export const RightWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.13rem;
  `

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--bs-white);
    box-shadow: 0px 33px 54px 0px rgba(99, 99, 99, 0.05);
    padding: 2.06rem;
    margin: 0 5rem;
    max-width: 29.5rem;
    @media (min-width: 769px) {
      min-width: 29.5rem;
    }
  `

export const InputWraps = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
`