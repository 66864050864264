import "./styles.scss";
import {
  CheckedCheckBoxIcon,
  CloseIcon2,
  ImageUploadIcon,
  UnchekedCheckBoxIcon,
} from "utils/contants";
import React, { useEffect, useRef, useState } from "react";
import {
  CheckboxWrap,
  ImageContainer,
  ImagePickerWrapper,
  MultipleImageWrapper,
} from "./styled";
import { getDominantColor } from 'utils/helper';


export function ImagePickers({ sliderImages, sliderImagesLimit = 1, name }) {
  const defaultValues = [];
  /**
   * The if condition is checking if the `sliderImages` prop exists. 
   * If it does, the condition checks if `sliderImages` is an array using the `Array.isArray()` method. 
   * If it is an array, the condition iterates over each item in the array using the `forEach()` method and pushes an object into the `defaultValues` array. 
   * The object has properties `src`, `name`, and `type`, all derived from the corresponding properties of the current item in the array. 
   * If `sliderImages` is not an array, the condition pushes a single object into `defaultValues`. This object has properties `src`, `name`, and `type`, all derived from the properties of `sliderImages`. 
   * In short, this if condition either pushes an array of objects derived from each item in `sliderImages` (if it's an array), or a single object derived from `sliderImages` (if it's not an array). 
   * This is useful because the `defaultValues` array will be an empty array if `sliderImages` is not provided, or is an empty array, and will be populated with objects derived from the `sliderImages` prop if it is provided and is an array, or is a single object.
  */
  if (sliderImages) {
    Array.isArray(sliderImages)
      ? sliderImages.forEach((img) =>
          defaultValues.push({
            src: img.src,
            name: img.name,
            type: "image/jpeg",
          }))
      : defaultValues.push({
          src: sliderImages.src,
          name: sliderImages.name,
          type: "image/jpeg",
        });
  }
  const [files, setFiles] = useState(
    Array.from({ length: sliderImagesLimit }, () => null)
  );
  const [previews, setPreviews] = useState(
    Array.from({ length: sliderImagesLimit }, () => null)
  );
  const fileInputRefs = useRef(
    Array.from({ length: sliderImagesLimit }, () => ({ current: null }))
  );


  /**
   * The function first prevents the default behavior of the event, which is to submit a form. 
   * It then retrieves the selected file from the event target, if it exists. 
   * After that, it creates new copies of the files and previews arrays using the spread operator. 
   * If a selected file exists, the function updates the appropriate index of the files and previews arrays. 
   * If no selected file exists, the function clears the value of the file input and updates the preview array accordingly.
   * 
   * The function is a pure function, meaning it does not modify any external state other than its own state variables. 
   * It relies on React's state management mechanism to update the state of the component.
   * 
   * The function is also idempotent, meaning it has the same output for the same input. 
   * This is because it does not have any side effects, such as modifying external state or making API calls.
   */
  const handleChange = (event, index) => {
    event.preventDefault();
    const selectedFile = event.target.files?.[0];
    const newFiles = [...files];
    const newPreviews = [...previews];

    if (selectedFile) {
      newFiles[index] = selectedFile;
      newPreviews[index] = URL.createObjectURL(selectedFile);
    } else {
      newFiles[index] = null;
      newPreviews[index] = null;
    }

    setFiles(newFiles);
    setPreviews(newPreviews);
  };


  /**
   * This function takes an index as a parameter, indicating which file should be deleted.
   * It creates new copies of the files and previews arrays, and then updates the state.
   * It also revokes the URL of the deleted preview and clears the value of the corresponding file input.
   */
  const handleDeleteFile = (index) => {
    const newFiles = [...files];
    const newPreviews = [...previews];
    const fileInputRef = fileInputRefs.current[index];
    const deletedPreview = newPreviews[index];
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    URL.revokeObjectURL(deletedPreview);
    newFiles[index] = null;
    newPreviews[index] = null;
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  // Clean up effect: revokes Object URLs when the component unmounts or when the `previews` state changes
  useEffect(() => {
    return () => {
      previews.forEach((preview, index) => {
        const fileInputRef = fileInputRefs.current[index];
        if (fileInputRef.current) {
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [previews]);

  // Initializes the component with a list of images passed in as props, creating File objects and Object URLs for each image
  useEffect(() => {
    if (sliderImages) {
      if (Array.isArray(sliderImages)) {
        const newFiles = sliderImages.map((img, index) => {
          const file = new File([`data:image/jpeg;base64,${img.source}`], img.name, { type: 'image/jpeg' });
          file.preview = URL.createObjectURL(file);
          return file;
        });
        const newPreviews = sliderImages.map((img) => img.src);
        setFiles(newFiles);
        setPreviews(newPreviews);
      } else {
        const file = new File([`data:image/jpeg;base64,${sliderImages.source}`], sliderImages.name, { type: 'image/jpeg' });
        file.preview = URL.createObjectURL(file);
        setFiles([file]);
        setPreviews([sliderImages.src]);
      }
    }
  }, [sliderImages]);

  
  /**
   * Render an image picker component for a specific index.
   * This component takes an index and renders an input of type "file", a preview image (if available), and a delete button.
   * The input is connected to a FileList in the component's state, and the preview image is connected to an Object URL in the component's state.
   * When the user selects a new image, the FileList and Object URL are updated.
   * When the user deletes an image, the FileList and Object URL are updated.
   */
  const renderImagePicker = (index) => {
    const fileInputRef = fileInputRefs.current?.[index];
    if (!fileInputRef) return null;
    return (
      <ImagePickerWrapper key={index}>
        <div className="image-picker-image">
          <input
            ref={(el) => {
              fileInputRef.current = el;
            }}
            type="file"
            name={sliderImagesLimit > 1 ? `${name}[${index}]` : name}
            onChange={(e) => handleChange(e, index)}
            accept="image/*"
            hidden={{ src: sliderImages?.[index]?.src, name: sliderImages?.[index]?.name}}
          />
          {!files?.[index] && (
            <button
              type="button"
              className="image-picker-input"
              onClick={() => fileInputRef.current?.click()}
            >
              <img src={ImageUploadIcon} alt="" className="image-upload-icon" />
              <p>Drag and Drop, Browse</p>
              <div>
                <p>Max Size: 2MB</p>
                <p>Dimensions: 1100*600</p>
              </div>
            </button>
          )}
          {previews?.[index] && (
            <img src={previews?.[index] || sliderImages?.[index]?.src} alt="preview" />
          )}
          {files?.[index] && (
            <button className="delete" onClick={() => handleDeleteFile(index)}>
              <img src={CloseIcon2} alt="" />
            </button>
          )}
        </div>
      </ImagePickerWrapper>
    );
  };

  if (sliderImagesLimit > 1) {
    return (
      <>
        {Array.from({ length: sliderImagesLimit }, (_, i) =>
          renderImagePicker(i)
        ).filter(Boolean)}
      </>
    );
  } else if (sliderImagesLimit === 1) {
    return renderImagePicker(0);
  }
}

export function MultipleImagePicker({ imageData, name }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  
  async function getDominantColorOfImage(imageSrc) {
      const dominantColor = await getDominantColor(imageSrc);
      return dominantColor;
  }

  const handleClick = async (source) => {
    const src = source.src;
    const title = source.title;
    const isSelected = selectedImages.includes(src);
    if (isSelected) {
      const index = selectedImages.indexOf(src);
      const indexTitle = selectedTitles.findIndex((item) => item.title === title);
      let newArr = [...selectedImages];
      let newTitleArr = [...selectedTitles];
      newArr.splice(index, 1);
      newTitleArr.splice(indexTitle, 1);
      setSelectedImages(newArr);
      setSelectedTitles(newTitleArr);
    } else {
      setSelectedImages([...selectedImages, src]);
      setSelectedTitles([...selectedTitles, {title, id: source.id}]);
    }
  };
  
  return (
    <MultipleImageWrapper className="col row row-cols-md-3 row-cols-xl-5">
      {imageData.length &&
        imageData.map((data, index) => {
          const dominiantColor = getDominantColorOfImage(data.src);
          return (
            <div className="content-wrapper row" key={index}>
              <ImageContainer
                color={`${dominiantColor}`}
                src={data.src}
                name={name}
                onClick={() => handleClick(data)}
              >
                <input type="hidden" name={name} value={JSON.stringify(selectedTitles.map((item) => item.title))} />
            
                <img className="img-card" src={data.src} alt={data.title} />
                <CheckboxWrap
                  className="custom-checkbox"
                  src={selectedImages.includes(data.src) ? CheckedCheckBoxIcon : UnchekedCheckBoxIcon}
                />
              </ImageContainer>
              <p className="title">{data.title}</p>
            </div>
          );
        })}
    </MultipleImageWrapper>
  );
}

