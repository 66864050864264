import './styles.scss';
import { Button } from "components/globalComponents"
import { Input } from "components/Input"
import { FormContainer, InputWraps, LeftWrap, LoginWrap, RightWrap } from "./styled"

// import LoginBanner from './../../assets/images/login-banner.png';
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom'
import { userLoginThunk } from "../../redux/thunk/userLoginThunk";
import { useNavigate } from "react-router-dom";
import { LogoImage, ProfileIcon } from "utils/contants";

function AdminLogin() {
  const isAdmin = useLocation().pathname.includes('admin');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reqmodel = Object.fromEntries(new FormData(event.target));

    try {
      const action = await dispatch(userLoginThunk(reqmodel)); // Dispatch the login thunk
      navigate('/company_profile/'); // Navigate to /company_profile on success

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  return (
    <LoginWrap className="row">
      <LeftWrap className="col-lg-6" isAdmin={true}>
        <h1>Making India a Healthy<br/>Place To Work</h1>
        <p className="text-color-white">Great Place to Work® Institute’s methodology is<br/>recognised as rigorous and<br/>objective and considered the Gold Standard.</p>
      </LeftWrap>
      <RightWrap className="col-lg-6">
      <div />
      <div className="form-wrapper">
        <img  className='login-logo' src={LogoImage} alt="LogoImage" />
        <h2 className="text-center">Admin Login</h2>
        <FormContainer onSubmit={handleSubmit}>
          <InputWraps>
              <Input
                icon={ProfileIcon}
                required
                label={"Admin Name"}
                type={"email"}
                placeholder={"Enter your name"}
                name={"admin_email"} />
              <Input
                icon={ProfileIcon}
                required
                label={"Password"}
                type={"password"}
                placeholder={"Password"}
                name={"password"}
              />
            </InputWraps>
          <Button type="submit" style={{marginTop: '6.06rem'}}>LOGIN</Button>
        </FormContainer>
      </div>
        <p className="mt-4 text-center">Copyright © 2023 Great Place To Work® India.<br/>All rights reserved.</p>
      </RightWrap>
    </LoginWrap>
  )
}

export default AdminLogin