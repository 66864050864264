import styled from "styled-components";

export const SideBarWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.13rem;
    width: fit-content;
`;

export const SideBarContainer = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    background-color: var(--bs-white);
    padding-inline: 2.0625rem 2.375rem;
    padding-block: 2.4375rem;
;
    @media (max-width: 767px) {
        padding-inline: 1.5rem;
    }
`;