import React from "react";
import "./styles.scss";
import { DialogButton, ToggleContainer, ToggleWrapper } from "./styled";

export const ToggleSwitch = ({defaultValue, label, required, name}) => {
  const [selected, setSelected] = React.useState(defaultValue);

  const handleChange = (e) => {
    e.preventDefault();
    setSelected(!selected)
  }

  return (
    <ToggleWrapper required={required}>
      {label && <p className="label">{label}</p>}
      <ToggleContainer selected={selected} onClick={(e) => handleChange(e)}>
        <input type="hidden" value={selected} name={name} />
        <DialogButton selected={selected}>
          {selected ? "Active" : "Inactive"}
        </DialogButton>
      </ToggleContainer>
    </ToggleWrapper>
  );
};

