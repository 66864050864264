import styled from "styled-components";

export const HeaderWrapper = styled.header`
    display: flex;
    flex-direction: row;
    padding: 1rem 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid var(--bs-light);
    background-color: var(--bs-white);

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2020;
    transform: translateY(0);
    transition: transform 0.3s ease;
    height: 5.5rem;
    & img {
        width: 3.5rem;
        height: 3.5rem;
    }

    @media (max-width: 767px) {
        padding-inline: 1.5rem;
    }
`;

export const HeaderRightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
        
`
