import React from 'react'
import { FormContainer, InputWraps } from '../AdminEdit/styled'
import { Input } from 'components/Input'
import { EmailIcon, LockIcon, ProfileIcon } from 'utils/contants'
import { Button } from 'components/globalComponents'
import { isValidEmail } from 'utils/validations'

function NewUser() {
    const [errors, setErrors] = React.useState({});

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const reqmodel = Object.fromEntries(new FormData(e.target));

            const newErrors = {};

            if (!reqmodel.user_name || reqmodel.user_name.trim() === '') {
                newErrors.user_name = "Please enter name";
            }

            if (!reqmodel.user_email || !isValidEmail(reqmodel.user_email.trim())) {
                newErrors.user_email = "Please enter valid email";
            }

            if (!reqmodel.user_password || reqmodel.user_password.trim() === '') {
                newErrors.user_password = "Please enter password";
            }

            setErrors(newErrors);

            if (Object.keys(newErrors).length === 0) {
                console.log(reqmodel);
            }

        } catch (error) {
            console.error('Error submitting form', error);
        }
    }
    return (
        <div className='d-flex justify-content-center align-items-center w-100'>
          <FormContainer onSubmit={handleSubmit}>
            <InputWraps>
                <Input icon={ProfileIcon} required label="Full Name" name="user_name" type="text" placeholder="Enter Your Name" error={errors.user_name}/>
                <Input icon={EmailIcon} required label="User Email" name="user_email" type="email" placeholder="Enter Your Email" error={errors.user_email}/>
                <Input icon={LockIcon} required label="User Password" name="user_password" type="password" placeholder="Enter Your Password" error={errors.user_password}/>
            </InputWraps>
            <Button type="submit" style={{marginTop: '6.06rem'}}>SUBMIT</Button>
    
          </FormContainer>
        </div>
      )
}

export default NewUser
