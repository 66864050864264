import React from 'react'

export function PageNotFound() {
  return (
    <div>
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-md-12 col-md-offset-0 text-center">
                    <h2>404 - Page not found</h2>
                    {/* <img src="https://s3.amazonaws.com/media.greatplacetowork.ca/404.svg" alt="" width="300" />
                    <h4>You may not be able to visit this page because of:</h4>
                    <p>an <strong>out-of-date bookmark/favorite</strong><br>
                        a search engine that has an <strong>out-of-date listing for this site</strong><br>
                        a <strong>mistyped address</strong><br>
                        you have <strong>no access</strong> to this page<br>
                        The requested resource was not found.&nbsp;
                    </p>
                    <a target="_self" href="/" class="btn btn-primary">Return the the Homepage</a> */}
                </div>
            </div>
        </div>
    </div>
  )
}
