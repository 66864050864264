import "./styles.scss";
import React, { useEffect, useRef, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";

import { companyList, getDummyData, globalFilterListOptions } from "utils/helper";
import { AddCircleIcon, EditIcon, LinkIcon } from "utils/contants";
import { Button, ButtonForm, SearchBox } from "components/globalComponents";
import { TableHeaderWrap } from "./styled";
import { SelectComponent } from "components/SelectComponent";
import { bottomTempalte } from "components/List";

const pageOptionList = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "200", value: 200 },
];

const urlComponent = (data, navigate) => {
  const { id } = data;
  return (
    <div className="actions">
      <ButtonForm type={"button"} color={"--bs-blue-light"} icon={LinkIcon}>
        Link
      </ButtonForm>
      <ButtonForm
        type={"button"}
        color={"--bs-blue-light"}
        icon={EditIcon}
        onClick={() => navigate(`/company_profile/quick_edit/${id}`)}
      >
        Edit
      </ButtonForm>
    </div>
  );
};

const handleCellRenderer = (data, header, navigate) => {
  switch (header.field) {
    case "url":
      return urlComponent(data, navigate);
    case "id_image":
      return (
        <img
          src={data.id_image}
          alt={data.data}
          className="id_image"
          // style={{
          //     width: "3rem",
          //     aspectRatio: 1,
          // }}
        />
      );
    default:
      return (
        <div className="no-action">
          {noActionComponent(data[header.field], header.field)}
        </div>
      );
  }
};

const noActionComponent = (data, header) => {
  let content;

  switch (typeof data) {
    case "string":
    case "number":
      if (header === "username_password") {
        return (
          <>
            <p className="title">{data}</p>
            <p className="no-action-2">*******</p>
          </>
        );
      } else {
        return <p className="title">{data}</p>;
      }
    case "object":
      return Object.entries(data).map(([key, value], index) => (
        <>
          <p className={index === 0 ? "title" : "no-action"}>{value}</p>
        </>
      ));
    default:
      return null;
  }
};

const headerComponent = (setRowLimit, setGlobalFilter) => {
  return (
    <TableHeaderWrap>
      <div className="header-component-left">
        <SearchBox>
          <input
            type="text"
            placeholder="Search"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </SearchBox>

        <SelectComponent
          leftLabel={"Industry"}
          // rightLabel={"Industry"}
          onChange={setGlobalFilter}
          optionList={[{ label: "All", value: "" }, ...globalFilterListOptions]}
        />
      </div>
      <SelectComponent
        leftLabel={"Show"}
        rightLabel={"Entries"}
        onChange={setRowLimit}
        optionList={pageOptionList}
      />
    </TableHeaderWrap>
  );
};

const onRowSelect = (e, navigate) => {
  navigate(`/company_profile/${e.data.id}`);
};

export function CompanyProfile() {
  const navigate = useNavigate();
  const tableRef = useRef();
  const [rowLimit, setRowLimit] = useState(9);
  const [headerData, setHeaderData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  const fetchData = async (page, rows) => {
    const response = await getDummyData({ ...companyList, page, rows });
    const data = response.data;
    setHeaderData(data.headerData);
    setRowData(data.rowData);
  };

  const handleAddNew = (e) => {
    navigate("/company_profile/add_new");
  }

  useEffect(() => {
    fetchData(0, 10);
  }, []);

  return (
    <>
      <div className="company_profile_header">
        <h2>Company Profile</h2>
        <Button onClick={(e) => handleAddNew(e)} type="buton" icon={AddCircleIcon}>Add New</Button>
      </div>
      <PrimeReactProvider>
        <DataTable
          //scroll
          scrollable
          // scrollHeight={'28rem'}
          //tablevalues
          ref={tableRef}
          value={rowData}
          selection={selectedRecord}
          rows={rowLimit}
          //selection
          selectionMode={"single"}
          onRowSelect={(e) => onRowSelect(e, navigate)}
          header={headerComponent(setRowLimit, setGlobalFilter)}
          onSelectionChange={(e) => setSelectedRecord(e.value)}
          //filter
          globalFilter={globalFilter}
          emptyMessage="No Data found."
          //styling
          className="table"
          //sort
          removableSort
          //pagination
          paginator
          paginatorTemplate={bottomTempalte}
        >
          {headerData.map((header, index) => (
            <Column
              key={index}
              sortable={["name", "username_password", "size"].includes(
                header.field
              )}
              style={{ width: header.field === "id_image" ? "8rem" : "auto" }}
              field={header.field}
              sortField="name.value"
              header={header.name}
              headerClassName="table-header"
              body={(props) => handleCellRenderer(props, header, navigate)}
            />
          ))}
        </DataTable>
      </PrimeReactProvider>
    </>
  );
}
