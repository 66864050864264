import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, DecoratorNode, createCommand } from "lexical";
import { useEffect } from "react";


export const $createFileNode = (file, fileType) => {
  return new FileNode(file, fileType);
};


export const INSERT_FILE_COMMAND = createCommand("INSERT_FILE_COMMAND");

// NODE
export class FileNode extends DecoratorNode {
  __file;
  __fileType;

  static getType() {
    return "file";
  }

  static clone(node) {
    return new FileNode(node.__file, node.__fileType, node.__key);
  }

  constructor(file, fileType, key) {
    super(file.name, key);

    this.__file = file;
    this.__fileType = fileType.toLowerCase();
  }

  /**
   * DOM that will be rendered by browser within contenteditable
   * This is what Lexical renders
   */
  createDOM(_config) {
    const dom = document.createElement("span");
    dom.className = "file-node";
    dom.innerText = this.__text;

    return dom;
  }

  static importJSON(serializedNode) {
    return $createFileNode(serializedNode.file, serializedNode.fileType);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "file",
      file: this.__file,
      fileType: this.__fileType,
    };
  }

  async getFile() {
    return this.__file;
  }

  async getFileDataUrl() {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;

      reader.readAsDataURL(this.__file);
    });
  }
}


// PLUGIN
export const FilePlugin = (props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([FileNode])) {
      throw new Error(
        "FilePlugin: FileNode not registered on editor (initialConfig.nodes)"
      );
    }

    return editor.registerCommand(
      INSERT_FILE_COMMAND,
      (payload) => {
        const fileNode = $createFileNode(payload);
        $insertNodeToNearestRoot(fileNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};
